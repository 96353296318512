<script setup>
import { useI18n } from '@/util';
import { LscAvatarSizes } from '../../../designsystem/components/media/avatar/constants';

const props = defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The size of the avatar.
   * @default 'sm'
   * @type {PropType<typeof LscAvatarSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (v) => LscAvatarSizes.includes(v),
  },
});

const emit = defineEmits(['handleCustomReportTrack', 'update']);

const { t } = useI18n();

const currentReport = computed(() => props.report);
const previousUsers = shallowRef([...(currentReport.value.users || [])]);
const numOfUsersAdded = shallowRef(0);

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}

watch(
  () => currentReport.value.users,
  (newUsers, oldUsers) => {
    if (newUsers?.length > oldUsers?.length) {
      numOfUsersAdded.value += newUsers.length - oldUsers.length;
    } else if (newUsers?.length < oldUsers?.length) {
      numOfUsersAdded.value = 0;
    }
    previousUsers.value = [...(newUsers || [])];
  },
  { deep: true, immediate: true },
);

function update() {
  if (numOfUsersAdded.value > 0) {
    handleCustomReportTrack('custom_reports', 'custom_report_users_added', 'activation', numOfUsersAdded.value);
    numOfUsersAdded.value = 0;
  }

  emit('update');
}
</script>

<template>
  <div class="flex items-center gap-1 text-body-1 text-subtle">
    <LswAssigneePicker
      v-model:assignees="currentReport.users"
      class="flex-start"
      :editable="true"
      includeProjectTeams
      includeSubteams
      includeCompanyTeams
      @afterLeave="update"
    >
      <template #activator="activator">
        <LscButton
          v-bind="activator.props"
          variant="plain-primary"
          @click="handleCustomReportTrack('custom_reports', 'custom_report_add_users_viewed', 'activation')"
        >
          <LswAssigneePickerActivator
            :avatars="activator.avatars"
            :blankTooltip="t('Add user or team')"
            :size="size"
            :max="5"
          />
          <LscIconButton
            v-if="currentReport.users?.length"
            v-LsdTooltip="t('Add user or team')"
            :ariaLabel="t('Add user or team')"
            icon="lsi-add"
            size="md"
          />
          <span v-else>{{ t('Add user or team') }}</span>
        </LscButton>
      </template>
    </LswAssigneePicker>
    <span class="ml-auto">{{ t('Can view') }}</span>
  </div>
</template>
