import { injectLocal, provideLocal } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePendo } from '@/api';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_RETAINER,
  BILLING_TYPE_STANDARD,
  DEFAULT_PREVIOUS_STEP_NAME,
  ONBOARDING_GOAL_GETTING_STARTED,
  ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED,
  PAGE_NAME_TO_PENDO_FIELD_MAP,
  STEP_ADD_CLIENT_GOAL,
  STEP_ADD_INTEGRATIONS,
  STEP_ADD_TASKS,
  STEP_BILLING_TYPE,
  STEP_BOARD_COLUMNS,
  STEP_GETTING_STARTED,
  STEP_GETTING_STARTED_CHECKLIST,
  STEP_INVITE_PEOPLE,
  STEP_PROJECT_NAME,
  STEP_PROJECT_TYPE_SELECTION,
  STEP_SELECT_GOAL,
  STEP_SET_BUDGET,
  STEP_SET_TEAM_RATES,
} from './constants';
import { useOnboardingWizard } from './useOnboardingWizard';

/**
 * Provides functions for tracking Pendo events related to the user onboarding flow.
 */

const symbol = Symbol('useOnboardingWizardTracking');

export const ONBOARDING_EVENT_NAME = 'ONBOARDING_EVENT';
export const SIMPLIFIED_ONBOARDING_EVENT_NAME = 'ICP20+_ONBOARDING_EVENT';
export const GOAL_BASED_ONBOARDING_EVENT_NAME = 'GOAL_BASED_ONBOARDING_EVENT';

export const ONBOARDING_EVENT_COMMON_METRICS = [
  'account_id',
  'company_size',
  'company_user_role',
  'company_sector',
  'plan_id',
];

export function OnboardingWizardTracking() {
  const router = useRouter();
  const { trackPendoEvent: trackEvent } = usePendo();
  const { onboardingState, currentStep, currentProjectId } = useOnboardingWizard();

  const isMainOnboarding = computed(
    () =>
      onboardingState.value.goal === ONBOARDING_GOAL_GETTING_STARTED ||
      onboardingState.value.goal === ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED,
  );

  const eventName = computed(() => {
    switch (onboardingState.value.goal) {
      case ONBOARDING_GOAL_GETTING_STARTED:
        return ONBOARDING_EVENT_NAME;
      case ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED:
        return SIMPLIFIED_ONBOARDING_EVENT_NAME;
      default:
        return GOAL_BASED_ONBOARDING_EVENT_NAME;
    }
  });

  const previousSteps = ref([]);
  const currentPage = computed(() => PAGE_NAME_TO_PENDO_FIELD_MAP[currentStep.value?.id]);
  const previousPage = computed(
    () =>
      PAGE_NAME_TO_PENDO_FIELD_MAP[previousSteps.value.at(-2)] ||
      (isMainOnboarding.value ? DEFAULT_PREVIOUS_STEP_NAME : undefined),
  );
  const entryPoint = computed(() =>
    router.currentRoute.value.path === '/welcome/set-up-teamwork' ? 'welcome_page' : 'project_tab',
  );
  const onboardingCurrentProjectId = computed(() => {
    if (currentProjectId.value && currentProjectId.value > 0) {
      return currentProjectId.value;
    }
    return undefined;
  });

  function getStepOnMountedMetadata(stepId, state) {
    const metadata = {};

    // regular onboarding steps and state
    if (state.goal === ONBOARDING_GOAL_GETTING_STARTED) {
      switch (stepId) {
        case STEP_SET_BUDGET:
          metadata.initial_billing_type = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        case STEP_SET_TEAM_RATES:
          metadata.billing_type = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        default:
      }
    }

    return metadata;
  }

  function getSkipClickedMetadata(stepId, state) {
    const metadata = {};

    // regular onboarding steps and state
    if (state.goal === ONBOARDING_GOAL_GETTING_STARTED) {
      switch (stepId) {
        case STEP_SET_BUDGET:
          metadata.initial_billing_type = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        case STEP_SET_TEAM_RATES:
          metadata.billing_type = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        default:
      }
    }
    return metadata;
  }

  function getContinueClickedMetadata(stepId, state) {
    const metadata = {};

    // regular onboarding steps and state
    if (state.goal === ONBOARDING_GOAL_GETTING_STARTED) {
      if (
        stepId === STEP_PROJECT_TYPE_SELECTION &&
        state?.data[STEP_PROJECT_TYPE_SELECTION]?.selectedTemplate.name !== undefined
      ) {
        metadata.template_name = state?.data[STEP_PROJECT_TYPE_SELECTION]?.selectedTemplate.name
          .replaceAll(' ', '_')
          .toLowerCase();
      }
      if (
        (stepId === STEP_GETTING_STARTED || stepId === STEP_GETTING_STARTED_CHECKLIST) &&
        (state?.data[STEP_GETTING_STARTED]?.alertShown === true ||
          state?.data[STEP_GETTING_STARTED_CHECKLIST]?.alertShown === true)
      ) {
        metadata.outcome_selection_prompt_viewed = 'true';
      }
      switch (stepId) {
        case STEP_GETTING_STARTED:
        case STEP_GETTING_STARTED_CHECKLIST:
          metadata.outcomes_selected_count =
            state?.data[STEP_GETTING_STARTED]?.outcomesSelectedCount ??
            state?.data[STEP_GETTING_STARTED_CHECKLIST]?.outcomesSelectedCount;
          metadata.outcomes_selected =
            state?.data[STEP_GETTING_STARTED]?.outcomesSelected ??
            state?.data[STEP_GETTING_STARTED_CHECKLIST]?.outcomesSelected;
          break;
        case STEP_PROJECT_TYPE_SELECTION:
          metadata.project_type = state?.data[STEP_PROJECT_TYPE_SELECTION]?.projectSelectedType;
          break;
        case STEP_ADD_TASKS:
          metadata.number_of_tasks_added = state?.data[STEP_ADD_TASKS]?.numberOfTasksAdded;
          break;
        case STEP_BOARD_COLUMNS:
          metadata.columns_added_count = state?.data[STEP_BOARD_COLUMNS]?.numberOfColumnsIntroduced;
          break;
        case STEP_INVITE_PEOPLE:
          metadata.invite_emails_added = state?.data[STEP_INVITE_PEOPLE]?.numberOfEmailsIntroduced;
          break;
        case STEP_ADD_INTEGRATIONS:
          metadata.integrations_selected =
            state?.data[STEP_ADD_INTEGRATIONS]?.selectedIntegrations.length > 0
              ? state?.data[STEP_ADD_INTEGRATIONS]?.selectedIntegrations.join(' | ')
              : 'none';
          break;
        // #region A2301 steps
        case STEP_BILLING_TYPE:
          metadata.billing_type_chosen = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        case STEP_SET_BUDGET:
          metadata.budget_type_chosen = state?.data[STEP_SET_BUDGET]?.type;
          metadata.budget_period_chosen = state?.data[STEP_SET_BUDGET]?.repeatValue;
          break;
        case STEP_SET_TEAM_RATES:
          metadata.billing_type = state?.data[STEP_BILLING_TYPE]?.type;
          break;
        // #endregion
        default:
          break;
      }
    } else if (state.goal === ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED) {
      const goalIdToLabelMapping = {
        0: 'manage_resources',
        1: 'maximise_profitability',
        2: 'gain_insights',
      };

      switch (stepId) {
        case STEP_SELECT_GOAL:
          metadata.goal_selected = goalIdToLabelMapping[state?.data?.[STEP_SELECT_GOAL]?.selectedGoalId];
          break;
        default:
          break;
      }
    } else {
      // goal-based onboarding steps and state
      metadata.goal_type = state.goal;

      switch (stepId) {
        case STEP_ADD_CLIENT_GOAL:
          metadata.goal_step = 'project_type';
          break;
        case STEP_PROJECT_NAME:
          metadata.project_type = state?.data[STEP_ADD_CLIENT_GOAL]?.clientProject ? 'client' : 'internal';
          metadata.goal_step = 'project_name';
          break;
        case STEP_BILLING_TYPE: {
          const billingType = {
            [BILLING_TYPE_STANDARD]: 'time_and_materials',
            [BILLING_TYPE_RETAINER]: 'retainer',
            [BILLING_TYPE_FIXED_FEE]: 'fixed_fee',
          };
          metadata.billing_type = billingType[state?.data[STEP_BILLING_TYPE]?.type];
          metadata.goal_step = 'budget_type';
          break;
        }
        default:
        // nothing
      }
    }

    return metadata;
  }

  function track(metadata) {
    trackEvent({
      eventName: eventName.value,
      commonMetrics: ONBOARDING_EVENT_COMMON_METRICS,
      metadata: {
        ...metadata,
        goal_type: onboardingState.value.goal,
      },
    });
  }

  function trackOnboardingStepOnMounted(stepId, previousStepId) {
    const pageType = PAGE_NAME_TO_PENDO_FIELD_MAP[stepId];
    const previousPageType =
      PAGE_NAME_TO_PENDO_FIELD_MAP[previousStepId] || (isMainOnboarding.value ? DEFAULT_PREVIOUS_STEP_NAME : undefined);

    const commonMetadata = {
      page_type: pageType,
      previous_page: previousPageType,
      event_action: `${pageType}_viewed`,
    };

    const dynamicMetadata = getStepOnMountedMetadata(stepId, onboardingState.value);

    const metadata = { ...commonMetadata, ...dynamicMetadata };

    track(metadata);
  }

  function trackOnboardingCommonPreviewClicked(stepId, activeView, clickSource) {
    const pageType = PAGE_NAME_TO_PENDO_FIELD_MAP[stepId];

    const metadata = {
      page_type: pageType,
      event_action: 'layout_view_clicked',
      layout_view_clicked: activeView,
      previous_page: previousPage.value,
    };

    if (clickSource !== undefined) {
      metadata.click_source = clickSource;
    }

    track(metadata);
  }

  function trackOnboardingStepSkipClicked(stepId) {
    const commonMetadata = {
      page_type: PAGE_NAME_TO_PENDO_FIELD_MAP[stepId],
      event_action: 'skip_clicked',
    };

    const dynamicMetadata = getSkipClickedMetadata(stepId, onboardingState.value);

    const metadata = { ...commonMetadata, ...dynamicMetadata };

    track(metadata);
  }

  function trackOnboardingStepContinueClicked(stepId, activeView) {
    const pageType = PAGE_NAME_TO_PENDO_FIELD_MAP[stepId];
    const previousStepId = previousSteps.value.at(-3);
    const previousPageType =
      PAGE_NAME_TO_PENDO_FIELD_MAP[previousStepId] || (isMainOnboarding.value ? DEFAULT_PREVIOUS_STEP_NAME : undefined);

    const commonMetadata = {
      page_type: pageType,
      event_action: 'continue_clicked',
      previous_page: previousPageType,
      entry_point: entryPoint.value,
      project_id: onboardingCurrentProjectId.value,
    };

    const dynamicMetadata = getContinueClickedMetadata(stepId, onboardingState.value);

    if (activeView !== undefined) {
      commonMetadata.layout_view_chosen = activeView;
    }

    const metadata = { ...commonMetadata, ...dynamicMetadata };

    track(metadata);
  }

  function trackGettingStartedStepContinueWithZeroSelection() {
    const commonMetadata = { page_type: 'outcome_selection', previous_page: 'company_information' };
    // we send an empty "continue_clicked" event also if 0 selections and prompt shown
    track({
      ...commonMetadata,
      event_action: 'continue_clicked',
      outcomes_selected_count: '0',
      outcomes_selected: 'none',
    });
    track({
      ...commonMetadata,
      event_action: 'outcome_selection_prompt_viewed',
    });
  }

  function trackOnboardingCompleted(metadata = {}) {
    track({
      previous_page: previousPage.value,
      page_type: 'onboarding_complete',
      event_action: 'onboarding_complete_viewed',
      entry_point: entryPoint.value,
      ...metadata,
    });
  }

  function trackGoalBasedClientTypeSelection(clientType) {
    track({
      event_action: 'project_type_clicked',
      event_label: clientType,
      goal_step: 'project_type',
      entry_point: 'welcome_page',
    });
  }

  function trackGoalBasedClientDropdownOptionSelected(selectionType) {
    track({
      event_action: 'select_client_dropdown_option_clicked',
      event_label: selectionType,
      goal_step: 'project_type',
      entry_point: 'welcome_page',
    });
  }

  function trackGoalBasedAddTeamMemberClicked(projectId) {
    track({
      event_action: 'add_team_member_clicked',
      entry_point: entryPoint.value,
      project_id: projectId,
    });
  }

  function trackGoalBasedProjectUsersAdded(numberUsersAdded, projectId) {
    track({
      event_action: 'add_team_member_save_clicked',
      number_users_added: numberUsersAdded,
      entry_point: entryPoint.value,
      project_id: projectId,
    });
  }

  function trackGoalBasedProjectUsersInvited(numberUsersInvited, projectId) {
    track({
      event_action: 'send_invites_clicked',
      number_users_invited: numberUsersInvited,
      entry_point: entryPoint.value,
      project_id: projectId,
    });
  }

  function trackGoalBasedAddTasks(metadata = {}) {
    trackEvent({
      eventName: GOAL_BASED_ONBOARDING_EVENT_NAME,
      commonMetrics: ONBOARDING_EVENT_COMMON_METRICS,
      metadata: {
        ...metadata,
        goal_type: 'add_tasks',
      },
    });
  }

  // watch history index in state to change to trigger tracking events
  watch(
    () => onboardingState.value?.stepHistoryIndex,
    (newIndex, oldIndex) => {
      const oldStepId = onboardingState.value.stepHistory.at(oldIndex)?.id;
      if (newIndex !== oldIndex) {
        // track continue/skip only when moving forward in flow, no back button tracking
        const forwardDirection = newIndex > oldIndex;
        if (forwardDirection) {
          if (onboardingState.value.data[oldStepId]?.skipped) {
            trackOnboardingStepSkipClicked(oldStepId);
          } else {
            const activeView =
              onboardingState.value.data[oldStepId]?.selectedView ?? onboardingState.value.data[oldStepId]?.view;
            trackOnboardingStepContinueClicked(oldStepId, activeView);
          }
        }
      }
    },
  );

  watch(
    () => currentStep.value,
    (newStep, oldStep) => {
      if (newStep.id !== oldStep?.id) {
        previousSteps.value.push(newStep.id);
        nextTick(() => {
          trackOnboardingStepOnMounted(newStep.id, oldStep?.id);
        });
      }
    },
  );

  return {
    trackOnboardingCommonPreviewClicked,
    trackGettingStartedStepContinueWithZeroSelection,
    trackOnboardingCompleted,
    trackGoalBasedClientDropdownOptionSelected,
    trackGoalBasedClientTypeSelection,
    trackGoalBasedAddTeamMemberClicked,
    trackGoalBasedProjectUsersAdded,
    trackGoalBasedProjectUsersInvited,
    trackGoalBasedAddTasks,
    currentPage,
    previousPage,
  };
}

export function provideOnboardingWizardTracking() {
  const onboarding = OnboardingWizardTracking();
  provideLocal(symbol, onboarding);
  return onboarding;
}

/** @type {() => ReturnType<OnboardingWizardTracking>} */
export function useOnboardingWizardTracking() {
  return injectLocal(symbol);
}
