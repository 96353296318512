<script setup>
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { usePreferences, useTaskActions, useTasklistActions } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  SAMPLE_AI_GENERATED_TASKS_DATA,
  STEP_ADD_AI_GENERATED_TASKS,
} from '../constants';
import { useOnboardingWizard } from '../useOnboardingWizard';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';
import { useAIGeneratedTasks } from './useAIGeneratedTasks';

defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['nextStep', 'previousStep', 'close']);

const { t } = useI18n();
const { currentProjectId, currentProject } = useOnboardingWizard();
const { generateTasksJson, promise, tasksJSON, hasError, errorType } = useAIGeneratedTasks();
const { createTasklist } = useTasklistActions();
const { createTask } = useTaskActions();
const router = useRouter();
const { onboardingTasklistGeneration } = usePreferences();
const toast = useLsToast();
const { trackGoalBasedAddTasks } = useOnboardingWizardTracking();

const inputEl = shallowRef(null);
const projectType = shallowRef('');
const briefDescription = shallowRef('');
const thumbsUpOrDownClicked = shallowRef(false);
const generateTasksClicked = shallowRef(false);
const projectTypeError = shallowRef(false);
const briefDescriptionError = shallowRef(false);
const tasklistGenerationLimit = shallowRef(10);

const tasklistId = computed(() => router.currentRoute.value.fullPath.match(/\/tasklists\/(\d+)\//)?.[1] || null);
const projectId = computed(() => {
  if (router.currentRoute.value.params.projectId) {
    return router.currentRoute.value.params.projectId;
  }
  return currentProjectId.value;
});
const projectName = computed(() => currentProject.value?.name);
const hasGeneratedTasks = computed(() => tasksJSON.value?.length > 0);
const previewData = computed(() => {
  let tasklistsData = SAMPLE_AI_GENERATED_TASKS_DATA.tasklists;
  if (hasGeneratedTasks.value) {
    let identifierCounter = 1;
    tasklistsData = tasksJSON.value.map((taskList, index) => {
      return {
        ...taskList,
        name: taskList.taskListName,
        tasks: taskList.tasks.map((task) => ({
          ...task,
          identifier: `${identifierCounter++}`,
          columnId: `${[1, 2, 3].find((id) => id === index + 1)}`,
        })),
      };
    });
  }
  return {
    tasklists: tasklistsData,
    boardColumns: SAMPLE_AI_GENERATED_TASKS_DATA.boardColumns,
  };
});
const reachedTasklistGenerationLimit = computed(
  () => onboardingTasklistGeneration.value.countByProjectId[projectId.value] >= tasklistGenerationLimit.value,
);
const generatedAtLeastOnce = computed(() => onboardingTasklistGeneration.value.countByProjectId[projectId.value] > 0);

const tabsDisplayed = computed(() =>
  tasklistId.value ? [PREVIEW_TAB_LIST] : [PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST],
);

const preselectedTab = computed(() => (tasklistId.value ? PREVIEW_TAB_LIST : PREVIEW_TAB_TABLE));

const entryPoint = computed(() => {
  if (router.currentRoute.value.path === '/welcome/set-up-teamwork') {
    return 'welcome_page';
  }
  if (router.currentRoute.value.path.includes('project-setup')) {
    return 'project_tab';
  }
  if (router.currentRoute.value.meta.view === 'list' || router.currentRoute.value.path.includes('/list')) {
    return 'list_view_blank_state';
  }
  if (router.currentRoute.value.meta.view === 'table' || router.currentRoute.value.path.includes('/table')) {
    return 'table_view_blank_state';
  }
  return '';
});
const tasklistIdField = computed(() => {
  if (tasklistId.value) {
    return tasklistId.value;
  }
  return 'all_lists';
});

function completeStep() {
  onboardingTasklistGeneration.value = {
    ...onboardingTasklistGeneration.value,
    completedByProjectId: {
      ...onboardingTasklistGeneration.value.completedByProjectId,
      [projectId.value]: true,
    },
  };
}

function nextStep() {
  if (hasGeneratedTasks.value) {
    createTasklists(tasksJSON.value);
    if (router.currentRoute.value.meta.view === 'list' || router.currentRoute.value.meta.view === 'table') {
      toast.success({
        title: t('Task lists have been added to your project'),
        message: t('Please reload the page if you do not see the changes'),
      });
      router.push({ path: `/projects/${projectId.value}/tasks` });
    } else {
      toast.success({
        title: t('Task lists have been added to your project'),
        actionText: t('Visit project'),
        action: () => router.push({ path: `/projects/${projectId.value}/tasks` }),
      });
    }
  }
  completeStep();
  trackGoalBasedAddTasks({
    event_action: 'finish_clicked',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
    project_id: projectId.value,
    entry_point: entryPoint.value,
    task_list_id: tasklistIdField.value,
  });
  emit('close', false);
}

function skipStep() {
  router.push({ path: `/projects/${projectId.value}/` });
  trackGoalBasedAddTasks({
    event_action: 'no_thanks_clicked',
    project_id: Number(projectId.value),
    entry_point: entryPoint.value,
    task_list_id: tasklistIdField.value,
  });
  emit('close', false);
}

function createTasklists(tasklists = []) {
  if (tasklistId.value) {
    return Promise.all(
      tasklists.map(async (tl) => {
        return Promise.all(
          tl.tasks.map((task) =>
            createTask({
              name: task.name,
              description: task.description,
              // tags: task.tag,
              priority: task.priority,
              startDate: DateTime.now(),
              dueDate: DateTime.now().plus({ days: task.dueDay }),
              estimateMinutes: task.estimatedMinutes,
              tasklistId: tasklistId.value,
            }),
          ),
        );
      }),
    );
  }
  return Promise.all(
    tasklists.map(async (tl) => {
      const { id: createdTasklistId } = await createTasklist({
        name: tl.taskListName,
        description: tl.taskListDescription,
        projectId: Number(projectId.value),
      });
      trackGoalBasedAddTasks({
        event_action: 'tasklist_created',
        tasklist_id: createdTasklistId,
        tasklist_name: tl.taskListName,
        tasklist_description: tl.taskListDescription,
        project_id: projectId.value,
        entry_point: entryPoint.value,
      });
      return Promise.all(
        tl.tasks.map((task) =>
          createTask({
            name: task.name,
            description: task.description,
            // tags: task.tag,
            priority: task.priority,
            startDate: DateTime.now(),
            dueDate: DateTime.now().plus({ days: task.dueDay }),
            estimateMinutes: task.estimatedMinutes,
            tasklistId: createdTasklistId,
          }),
        ),
      );
    }),
  );
}

function generateTasks() {
  if (reachedTasklistGenerationLimit.value) {
    toast.critical(t('You have reached the limit for generating tasks'));
    return;
  }

  projectTypeError.value = projectType.value.length < 5 || /^[0-9]+$/.test(projectType.value);
  briefDescriptionError.value = briefDescriptionError.value.length < 10 || /^[0-9]+$/.test(briefDescription.value);
  thumbsUpOrDownClicked.value = false;
  generateTasksClicked.value = true;

  if (tasklistId.value) {
    generateTasksJson(projectName.value, projectType.value, briefDescription.value, tasklistId.value);
    trackGoalBasedAddTasks({
      event_action: 'generate_tasks_clicked',
      project_type_prompt: projectType.value,
      project_description_prompt: briefDescription.value,
      project_id: projectId.value,
      entry_point: entryPoint.value,
      task_list_id: tasklistIdField.value,
    });
  } else {
    generateTasksJson(projectName.value, projectType.value, briefDescription.value);
    trackGoalBasedAddTasks({
      event_action: 'generate_tasks_clicked',
      project_type_prompt: projectType.value,
      project_description_prompt: briefDescription.value,
      project_id: projectId.value,
      entry_point: entryPoint.value,
      task_list_id: 'all_lists',
    });
  }
  onboardingTasklistGeneration.value = {
    ...onboardingTasklistGeneration.value,
    countByProjectId: {
      ...onboardingTasklistGeneration.value.countByProjectId,
      [projectId.value]: onboardingTasklistGeneration.value.countByProjectId[projectId.value]
        ? onboardingTasklistGeneration.value.countByProjectId[projectId.value] + 1
        : 1,
    },
  };
}

function thumbsUpClicked() {
  thumbsUpOrDownClicked.value = true;
  trackGoalBasedAddTasks({
    event_action: 'feedback_button_clicked',
    feedback_type: 'thumbs_up',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
    project_id: projectId.value,
    entry_point: entryPoint.value,
    task_list_id: tasklistIdField.value,
  });
}

function thumbsDownClicked() {
  thumbsUpOrDownClicked.value = true;
  trackGoalBasedAddTasks({
    event_action: 'feedback_button_clicked',
    feedback_type: 'thumbs_down',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
    project_id: projectId.value,
    entry_point: entryPoint.value,
    task_list_id: tasklistIdField.value,
  });
}

watch(hasGeneratedTasks, () => {
  if (hasGeneratedTasks.value) {
    toast.success({
      title: reachedTasklistGenerationLimit.value ? t('Your tasks are ready') : t('Your tasks have been generated'),
      message: t(
        "Review and click 'Finish' to add these tasks to your project. You can always edit them later by visiting your project. | You can use AI to regenerate your tasks {n} more time | You can use AI to regenerate your tasks up to {n} more times",
        { n: tasklistGenerationLimit.value - onboardingTasklistGeneration.value.countByProjectId[projectId.value] },
      ),
    });
  }
});

watch(hasError, () => {
  if (hasError.value) {
    toast.critical(t('Error generating tasks. Please try again.'));
    trackGoalBasedAddTasks({
      event_action: 'error',
      error_type: errorType.value,
      project_id: projectId.value,
      entry_point: entryPoint.value,
    });
    trackGoalBasedAddTasks({
      event_action: 'error_shown',
      project_type_prompt: projectType.value,
      project_description_prompt: briefDescription.value,
      entry_point: entryPoint.value,
      project_id: projectId.value,
      task_list_id: tasklistIdField.value,
    });
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="
      tasklistId ? t('Build your task list with AI-Generated tasks') : t('Build your project with AI-Generated tasks')
    "
    :description="
      tasklistId
        ? t(
            'Let {teamwork}’s AI assistant create a tailored task list for you. Using our internal and industry best practices, we’ll create your tasks to help you meet your goals efficiently.',
            { teamwork: 'Teamwork.com' },
          )
        : t(
            'Let {teamwork}’s AI assistant create a tailored project for you. Using our internal and industry best practices, we’ll organize your tasks into structured task lists to help you meet your goals efficiently.',
            { teamwork: 'Teamwork.com' },
          )
    "
    class="-mb-4 mt-4"
  >
    <p class="font-semibold">
      {{
        tasklistId
          ? t('What type of task list would you like to create?')
          : t('What type of project would you like to create?')
      }}
    </p>
    <VTextField
      ref="inputEl"
      v-model.trim="projectType"
      :placeholder="t('e.g. Website development')"
      :maxLength="255"
      :autoFocus="true"
      class="my-3"
    />

    <p class="font-semibold">
      {{
        tasklistId
          ? t('Please provide a brief description or objective for this task list')
          : t('Please provide a brief description or objective for this project')
      }}
    </p>
    <VTextarea
      v-model.trim="briefDescription"
      :placeholder="
        tasklistId
          ? t(
              'e.g. The objective of this task list is to design a user-friendly, visually appealing website that effectively showcases our brand, engages visitors, and drives conversions.',
            )
          : t(
              'e.g. The objective of this project is to design a user-friendly, visually appealing website that effectively showcases our brand, engages visitors, and drives conversions.',
            )
      "
      rows="4"
      :autoGrow="true"
      class="my-3"
    />

    <LscButton
      variant="secondary"
      :disabled="Boolean(promise) || reachedTasklistGenerationLimit"
      class="mb-4 mt-2"
      @click="generateTasks"
    >
      {{ t('Generate tasks') }}
    </LscButton>

    <LscAlert
      v-if="reachedTasklistGenerationLimit"
      layout="alert"
      :title="t('You have reached the limit for generating tasks')"
      :message="t('Visit your project to make any further changes in the tasks')"
      icon="lsi-alert"
      variant="warning-subdued"
    />
    <LscAlert
      v-else-if="Boolean(promise) && (briefDescriptionError || projectTypeError)"
      layout="alert"
      :message="t('We noticed some info was missing, so we’ve used your account details to generate your tasks.')"
      icon="lsi-alert"
      variant="warning-subdued"
    />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :preselectedTab="preselectedTab"
        :tabs="tabsDisplayed"
        :tabsClickable="!Boolean(tasklistId)"
        :stepId="STEP_ADD_AI_GENERATED_TASKS"
        :tasksDataFromTemplate="hasGeneratedTasks"
        :tasksData="previewData"
        :isPreviewDataLoading="Boolean(promise)"
      />
      <div
        v-if="hasGeneratedTasks && !thumbsUpOrDownClicked && !Boolean(promise)"
        class="-mb-14 mt-4 flex justify-center"
      >
        <p class="mx-2 mt-2">
          {{ t('How would you rate the generated task lists?') }}
        </p>
        <LscIconButton
          v-LsdTooltip="t('Good')"
          :ariaLabel="t('Thumbs up')"
          icon="lsi-thumbs-up"
          @click.stop="thumbsUpClicked"
        />
        <LscIconButton
          v-LsdTooltip="t('Bad')"
          :ariaLabel="t('Thumbs down')"
          icon="lsi-thumbs-up"
          class="rotate-180"
          @click.stop="thumbsDownClicked"
        />
      </div>
    </template>
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :isNextStepButtonDisabled="Boolean(promise) || !generatedAtLeastOnce"
    :nextStep="nextStep"
    :skipButtonText="t('No thanks, I\'ll create my own tasks')"
    :showSkipButton="true"
    :skipStep="skipStep"
  />
</template>
