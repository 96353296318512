import './VListItem.variants.css';

export const VListItemCommandCenter = {
  class: 'VListItemCommandCenter',
  link: true,
};

export const VListItemSidebarProjectsDrawer = {
  class: 'VListItemSidebarProjectsDrawer',
  link: true,
};

export const VListItemSidebarHelpCenterDrawer = {
  class: 'VListItemSidebarHelpCenterDrawer',
  link: true,
};

export const VListItemIconTitle = {
  class: 'VListItemIconTitle',
};

export const VListItemFilter = {
  class: 'VListItemFilter',
};

export const VListItemTaskBoardColumn = {
  class: 'VListItemTaskBoardColumn',
};
