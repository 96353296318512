<script setup>
import { useExperimentR2410, usePreferences } from '@/api';

const AccountIndustrySubcategoryDialogPanel = defineAsyncComponent(
  () => import('./AccountIndustrySubcategoryDialogPanel.vue'),
);

const { industrySubcategoryModalDismissed } = usePreferences();
const { isExpR2410Variation } = useExperimentR2410();

const isITServicesAccountDialogVisible = computed(
  () => !industrySubcategoryModalDismissed.value && isExpR2410Variation.value,
);
</script>

<template>
  <WidgetDialog v-model="isITServicesAccountDialogVisible">
    <template #default="{ close }">
      <AccountIndustrySubcategoryDialogPanel @close="close" />
    </template>
  </WidgetDialog>
</template>
