import { useI18n } from '@/util';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_NO_BUDGET,
  BILLING_TYPE_RETAINER,
  BILLING_TYPE_STANDARD,
  ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS,
  ONBOARDING_GOAL_ADD_BUDGET,
  ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES,
  ONBOARDING_GOAL_CREATE_PROJECT,
  ONBOARDING_GOAL_GETTING_STARTED,
  ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED,
  ONBOARDING_GOAL_SAVE_AS_TEMPLATE,
  PREVIEW_TAB_BOARD,
  PROGRESS_STEP_AI_GENERATED_TASKS,
  PROGRESS_STEP_BUDGET,
  PROGRESS_STEP_COMPANY_INFO,
  PROGRESS_STEP_PERSONAL_INFO,
  PROGRESS_STEP_PROJECT,
  PROGRESS_STEP_TEAM,
  PROJECT_TYPE_TEMPLATE,
  STEP_ADD_AI_GENERATED_TASKS,
  STEP_ADD_CLIENT,
  STEP_ADD_CLIENT_GOAL,
  STEP_ADD_INTEGRATIONS,
  STEP_ADD_TASKS,
  STEP_BILLING_TYPE,
  STEP_BOARD_COLUMNS,
  STEP_CLIENT_PROJECT,
  STEP_GETTING_STARTED,
  STEP_GETTING_STARTED_CHECKLIST,
  STEP_INVITE_PEOPLE,
  STEP_INVITE_USERS_AND_SET_TEAM_RATES,
  STEP_LAST_STEP,
  STEP_PROJECT_NAME,
  STEP_PROJECT_TYPE_SELECTION,
  STEP_SAVE_AS_TEMPLATE,
  STEP_SELECT_GOAL,
  STEP_SELECT_VIEW,
  STEP_SET_BUDGET,
  STEP_SET_TEAM_RATES,
  STEP_SIGNUP_SURVEY,
  // STEP_TEST,
} from './constants';
import StepAddAIGeneratedTasks from './steps/OnboardingWizardStepsAddAIGeneratedTasks.vue';
import StepAddClient from './steps/OnboardingWizardStepsAddClient.vue';
import StepAddClientGoal from './steps/OnboardingWizardStepsAddClientGoal.vue';
import StepAddIntegrations from './steps/OnboardingWizardStepsAddIntegrations.vue';
import StepAddTasks from './steps/OnboardingWizardStepsAddTasks.vue';
import StepBillingType from './steps/OnboardingWizardStepsBillingType.vue';
import StepBoardColumns from './steps/OnboardingWizardStepsBoardColumns.vue';
import StepClientProject from './steps/OnboardingWizardStepsClientProject.vue';
import StepGettingStarted from './steps/OnboardingWizardStepsGettingStarted.vue';
import StepGettingStartedChecklist from './steps/OnboardingWizardStepsGettingStartedChecklist.vue';
import StepInvitePeople from './steps/OnboardingWizardStepsInvitePeople.vue';
import StepInviteUsersAndSetTeamRates from './steps/OnboardingWizardStepsInviteUsersAndSetTeamRates.vue';
import StepLastStep from './steps/OnboardingWizardStepsLastStep.vue';
import StepProjectName from './steps/OnboardingWizardStepsProjectName.vue';
import StepProjectTypeSelection from './steps/OnboardingWizardStepsProjectTypeSelection.vue';
import StepSaveAsTemplate from './steps/OnboardingWizardStepsSaveAsTemplate.vue';
import StepSelectGoal from './steps/OnboardingWizardStepsSelectGoal.vue';
import StepSelectView from './steps/OnboardingWizardStepsSelectView.vue';
import StepSetBudget from './steps/OnboardingWizardStepsSetBudget.vue';
import StepSetTeamRates from './steps/OnboardingWizardStepsSetTeamRates.vue';
import StepSignupSurvey from './steps/OnboardingWizardStepsSignupSurvey.vue';
import StepWorkflowStages from './steps/OnboardingWizardStepsWorkflowStages.vue';
import { useOnboardingWizardFeatures } from './useOnboardingWizardFeatures';
// import StepTest from './steps/OnboardingWizardStepsTest.vue';

// TODO: make steps async components, because we'll be providing onboarding wizard throughout the whole app
// const StepProjectName = markRaw(defineAsyncComponent(() => import('./steps/OnboardingWizardStepsProjectName.vue')));

export function useOnboardingSteps(onboardingState) {
  const { onboardingFeatures } = useOnboardingWizardFeatures();
  const { t } = useI18n();

  // state data to be used to do branching logic in steps
  const state = computed(() => onboardingState.value.data);

  const ONBOARDING_GOAL_FIRST_STEP = {
    [ONBOARDING_GOAL_GETTING_STARTED]: onboardingFeatures.value.gettingStartedRedesignEnabled
      ? STEP_GETTING_STARTED
      : STEP_GETTING_STARTED_CHECKLIST,
    [ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED]: STEP_SELECT_GOAL,
    [ONBOARDING_GOAL_ADD_BUDGET]: STEP_BILLING_TYPE,
    [ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES]: STEP_INVITE_USERS_AND_SET_TEAM_RATES,
    [ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS]: STEP_ADD_AI_GENERATED_TASKS,
    [ONBOARDING_GOAL_SAVE_AS_TEMPLATE]: STEP_SAVE_AS_TEMPLATE,
    [ONBOARDING_GOAL_CREATE_PROJECT]: STEP_ADD_CLIENT_GOAL,
  };

  const ONBOARDING_GOAL_PROGRESS_STEPS = {
    [ONBOARDING_GOAL_GETTING_STARTED]: [
      {
        id: PROGRESS_STEP_PERSONAL_INFO,
        label: t('Personal Info'),
      },
      {
        id: PROGRESS_STEP_COMPANY_INFO,
        label: t('Company Info'),
      },
      {
        id: PROGRESS_STEP_PROJECT,
        label: t('Set up a Project'),
      },
    ],
    [ONBOARDING_GOAL_ADD_BUDGET]: [
      {
        id: PROGRESS_STEP_BUDGET,
        label: t('Select a budget type'),
      },
      {
        id: PROGRESS_STEP_BUDGET,
        label: t('Set up a budget'),
      },
    ],
    [ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES]: [
      {
        id: PROGRESS_STEP_TEAM,
        label: t('Invite users and set team rates'),
      },
    ],
    [ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS]: [
      {
        id: PROGRESS_STEP_AI_GENERATED_TASKS,
        label: t('Fast-track your project set up'),
      },
    ],
    [ONBOARDING_GOAL_SAVE_AS_TEMPLATE]: [
      {
        id: PROGRESS_STEP_PROJECT,
        label: t('Project template'),
      },
    ],
    [ONBOARDING_GOAL_CREATE_PROJECT]: [
      {
        id: PROGRESS_STEP_PROJECT,
        label: t('Select project type'),
      },
      {
        id: PROGRESS_STEP_PROJECT,
        label: t('Add project name'),
      },
    ],
  };

  const ONBOARDING_GOAL_FLOWS = {
    [ONBOARDING_GOAL_GETTING_STARTED]: {
      title: '',
      steps: {
        [STEP_GETTING_STARTED]: {
          id: STEP_GETTING_STARTED,
          progressStep: PROGRESS_STEP_COMPANY_INFO,
          component: markRaw(StepGettingStarted),
          nextStep: () => {
            if (onboardingFeatures.value.addClientCreationFlowEnabled) {
              return STEP_ADD_CLIENT;
            }
            if (onboardingFeatures.value.templatesFlowEnabled) {
              return STEP_PROJECT_TYPE_SELECTION;
            }
            return STEP_PROJECT_NAME;
          },
        },
        [STEP_GETTING_STARTED_CHECKLIST]: {
          id: STEP_GETTING_STARTED_CHECKLIST,
          progressStep: PROGRESS_STEP_COMPANY_INFO,
          component: markRaw(StepGettingStartedChecklist),
          nextStep: () => {
            if (onboardingFeatures.value.addClientCreationFlowEnabled) {
              return STEP_ADD_CLIENT;
            }

            if (onboardingFeatures.value.templatesFlowEnabled) {
              return STEP_PROJECT_TYPE_SELECTION;
            }

            return STEP_PROJECT_NAME;
          },
        },
        [STEP_ADD_CLIENT]: {
          id: STEP_ADD_CLIENT,
          progressStep: PROGRESS_STEP_COMPANY_INFO,
          component: markRaw(StepAddClient),
          nextStep: () =>
            onboardingFeatures.value.templatesFlowEnabled ? STEP_PROJECT_TYPE_SELECTION : STEP_PROJECT_NAME,
        },
        [STEP_PROJECT_TYPE_SELECTION]: {
          id: STEP_PROJECT_TYPE_SELECTION,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepProjectTypeSelection),
          nextStep: () =>
            state.value[STEP_PROJECT_TYPE_SELECTION]?.projectSelectedType === PROJECT_TYPE_TEMPLATE
              ? STEP_PROJECT_NAME
              : STEP_ADD_TASKS,
        },
        [STEP_PROJECT_NAME]: {
          id: STEP_PROJECT_NAME,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepProjectName),
          nextStep: () =>
            state.value[STEP_PROJECT_TYPE_SELECTION]?.projectSelectedType === PROJECT_TYPE_TEMPLATE
              ? STEP_SELECT_VIEW
              : STEP_ADD_TASKS,
        },
        [STEP_ADD_TASKS]: {
          id: STEP_ADD_TASKS,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepAddTasks),
          nextStep: () => STEP_SELECT_VIEW,
        },
        [STEP_SELECT_VIEW]: {
          id: STEP_SELECT_VIEW,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSelectView),
          nextStep: () =>
            state.value[STEP_SELECT_VIEW]?.view === PREVIEW_TAB_BOARD &&
            state.value[STEP_PROJECT_TYPE_SELECTION]?.projectSelectedType !== PROJECT_TYPE_TEMPLATE
              ? STEP_BOARD_COLUMNS
              : STEP_INVITE_PEOPLE,
        },
        [STEP_BOARD_COLUMNS]: {
          id: STEP_BOARD_COLUMNS,
          progressStep: PROGRESS_STEP_PROJECT,
          component: onboardingFeatures.value.workflowsEnabled
            ? markRaw(StepWorkflowStages)
            : markRaw(StepBoardColumns),
          nextStep: () => STEP_INVITE_PEOPLE,
        },
        [STEP_INVITE_PEOPLE]: {
          id: STEP_INVITE_PEOPLE,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepInvitePeople),
          showSkipButton: true,
          nextStep: () => {
            if (onboardingFeatures.value.financeFlowEnabled) {
              return STEP_CLIENT_PROJECT;
            }

            if (onboardingFeatures.value.integrationsFlowEnabled) {
              return STEP_ADD_INTEGRATIONS;
            }

            if (onboardingFeatures.value.surveyFlowEnabled) {
              return STEP_SIGNUP_SURVEY;
            }

            return STEP_LAST_STEP;
          },
        },
        [STEP_CLIENT_PROJECT]: {
          id: STEP_CLIENT_PROJECT,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepClientProject),
          nextStep: () => {
            if (state.value[STEP_CLIENT_PROJECT]?.clientProject) {
              return STEP_BILLING_TYPE;
            }

            if (onboardingFeatures.value.integrationsFlowEnabled) {
              return STEP_ADD_INTEGRATIONS;
            }

            if (onboardingFeatures.value.surveyFlowEnabled) {
              return STEP_SIGNUP_SURVEY;
            }

            return STEP_LAST_STEP;
          },
        },
        [STEP_BILLING_TYPE]: {
          id: STEP_BILLING_TYPE,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepBillingType),
          showSkipButton: true,
          nextStep: () => {
            const type = state.value[STEP_BILLING_TYPE]?.type;
            if ([BILLING_TYPE_RETAINER, BILLING_TYPE_STANDARD, BILLING_TYPE_FIXED_FEE].includes(type)) {
              return STEP_SET_BUDGET;
            }

            if (type === BILLING_TYPE_NO_BUDGET) {
              return STEP_SET_TEAM_RATES;
            }

            if (onboardingFeatures.value.integrationsFlowEnabled) {
              return STEP_ADD_INTEGRATIONS;
            }

            if (onboardingFeatures.value.surveyFlowEnabled) {
              return STEP_SIGNUP_SURVEY;
            }

            return STEP_LAST_STEP;
          },
        },
        [STEP_SET_BUDGET]: {
          id: STEP_SET_BUDGET,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSetBudget),
          nextStep: () => STEP_SET_TEAM_RATES,
        },
        [STEP_SET_TEAM_RATES]: {
          id: STEP_SET_TEAM_RATES,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSetTeamRates),
          showSkipButton: true,
          nextStep: () => {
            if (onboardingFeatures.value.integrationsFlowEnabled) {
              return STEP_ADD_INTEGRATIONS;
            }

            if (onboardingFeatures.value.surveyFlowEnabled) {
              return STEP_SIGNUP_SURVEY;
            }

            return STEP_LAST_STEP;
          },
        },
        [STEP_ADD_INTEGRATIONS]: {
          id: STEP_ADD_INTEGRATIONS,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepAddIntegrations),
          showSkipButton: true,
          nextStep: () => (onboardingFeatures.value.surveyFlowEnabled ? STEP_SIGNUP_SURVEY : STEP_LAST_STEP),
        },
        [STEP_SIGNUP_SURVEY]: {
          id: STEP_SIGNUP_SURVEY,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSignupSurvey),
          nextStep: () => STEP_LAST_STEP,
        },
        // currently not in use
        [STEP_SAVE_AS_TEMPLATE]: {
          id: STEP_SAVE_AS_TEMPLATE,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSaveAsTemplate),
          showSkipButton: true,
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },

        // not part of flow now, keeping it here for dev to test stuff
        // [STEP_TEST]: {
        //   id: STEP_TEST,
        //   progressStep: PROGRESS_STEP_PROJECT,
        //   component: markRaw(StepTest),
        //   nextStep: () => undefined,
        // },
      },
    },
    [ONBOARDING_GOAL_GETTING_STARTED_SIMPLIFIED]: {
      steps: {
        [STEP_SELECT_GOAL]: {
          id: STEP_SELECT_GOAL,
          component: markRaw(StepSelectGoal),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
    [ONBOARDING_GOAL_ADD_BUDGET]: {
      title: t('Add a budget'),
      steps: {
        [STEP_BILLING_TYPE]: {
          id: STEP_BILLING_TYPE,
          progressStep: PROGRESS_STEP_BUDGET,
          component: markRaw(StepBillingType),
          nextStep: () => STEP_SET_BUDGET,
        },
        [STEP_SET_BUDGET]: {
          id: STEP_SET_BUDGET,
          progressStep: PROGRESS_STEP_BUDGET,
          component: markRaw(StepSetBudget),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
    [ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES]: {
      title: t('Managing projects'),
      steps: {
        [STEP_INVITE_USERS_AND_SET_TEAM_RATES]: {
          id: STEP_INVITE_USERS_AND_SET_TEAM_RATES,
          progressStep: PROGRESS_STEP_TEAM,
          component: markRaw(StepInviteUsersAndSetTeamRates),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
    [ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS]: {
      title: t('Fast-track your project set up'),
      steps: {
        [STEP_ADD_AI_GENERATED_TASKS]: {
          id: STEP_ADD_AI_GENERATED_TASKS,
          progressStep: PROGRESS_STEP_AI_GENERATED_TASKS,
          component: markRaw(StepAddAIGeneratedTasks),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
    [ONBOARDING_GOAL_SAVE_AS_TEMPLATE]: {
      title: t('Managing projects'),
      steps: {
        [STEP_SAVE_AS_TEMPLATE]: {
          id: STEP_SAVE_AS_TEMPLATE,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepSaveAsTemplate),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
    [ONBOARDING_GOAL_CREATE_PROJECT]: {
      title: t('Managing projects'),
      steps: {
        [STEP_ADD_CLIENT_GOAL]: {
          id: STEP_ADD_CLIENT_GOAL,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepAddClientGoal),
          nextStep: () => STEP_PROJECT_NAME,
        },
        [STEP_PROJECT_NAME]: {
          id: STEP_PROJECT_NAME,
          progressStep: PROGRESS_STEP_PROJECT,
          component: markRaw(StepProjectName),
          nextStep: () => STEP_LAST_STEP,
        },
        // this should _always_ be the last step
        [STEP_LAST_STEP]: {
          id: STEP_LAST_STEP,
          component: markRaw(StepLastStep),
          nextStep: () => undefined,
        },
      },
    },
  };

  const flow = ONBOARDING_GOAL_FLOWS[onboardingState.value.goal];
  const firstStep = ONBOARDING_GOAL_FIRST_STEP[onboardingState.value.goal];
  const progressSteps = ONBOARDING_GOAL_PROGRESS_STEPS[onboardingState.value.goal] ?? [];

  return {
    firstStep,
    title: flow.title,
    steps: flow.steps,
    progressSteps,
  };
}
