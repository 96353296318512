<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useTeamsV1Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  taskId: {
    type: Number,
    default: null,
  },
  tasklistId: {
    type: Number,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();

const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const pageSize = 10;
const count = shallowRef(-1);
const filterType = 'teams';

const state = useTeamsV1Loader({
  taskId: props.taskId,
  tasklistId: props.tasklistId,
  params: computed(() => ({
    ...props.loaderParams,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});
const { items } = state;

const missingTeamIds = shallowRef([]);
const missingTeamState = useTeamsV1Loader({
  params: computed(() => ({
    ...props.loaderParams,
    teamIds: missingTeamIds.value.join(','),
  })),
  count: computed(() => missingTeamIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingTeamState.items,
);

const teamIds = computed({
  get() {
    return typeof params.value[props.name] === 'string' && params.value[props.name] !== ''
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const teams = computeAll(teamIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(teamIds), missingTeamIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    teams.value.map((team) => ({
      name: team.name,
      type: filterType,
      team, // Necessary to load the icon in LscAvatar
      delete: () => {
        teamIds.value = teamIds.value.filter((id) => id !== team.id);
      },
    })),
  ),
);

useFilterCount(computed(() => teamIds.value.length));

function toggleTeam({ id }) {
  if (teamIds.value.includes(id)) {
    teamIds.value = teamIds.value.filter((teamId) => teamId !== id);
  } else {
    teamIds.value = [...teamIds.value, id];
  }
}

function icon(id) {
  return teamIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="teams.length > 0"
        icon="lsi-group"
        :data-identifier="`${dataIdentifierPrefix}-filter-${name}-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <LscSheet class="flex w-[--filter-dropdown-width] flex-col px-0">
      <LscSearchBar v-model="searchTerm" :clearable="true" :placeholder="t('Search teams')" autofocus class="mx-4" />
      <WidgetLoadingState :state="state" :blankTitle="t('No teams found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem v-for="team in items" :key="team.id" @click="toggleTeam(team)">
              <div class="flex items-center gap-2">
                <LscAvatar v-bind="getLsAvatarProps({ team })" />
                <LscOverflowEllipsis class="flex-1 text-body-1">{{ team.name }}</LscOverflowEllipsis>
                <LscIcon :icon="icon(team.id)" class="text-icon-subtle" size="sm" />
              </div>
            </VListItem>
            <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
    </LscSheet>
  </WidgetMenu>
</template>
