<script setup>
import { syncRef } from '@vueuse/core';
import { useTagsV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterBoolean, normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'include',
  },
  showMatchAllTagsFilter: {
    type: Boolean,
    default: false,
  },
  matchAllTagsParam: {
    type: String,
    default: 'matchAllTags',
  },
  matchAllTagsTooltip: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();

const { activeFilter, dataIdentifierPrefix } = useFilter();
const pageSize = 10;
const count = shallowRef(-1);
const filterType = 'tags';

const projectTagsParams = computed(() => {
  const ids = props.projectId ? [0, props.projectId] : [];
  return {
    projectIds: ids.join(','),
    orderMode: 'desc',
    orderBy: 'projectdatelastused',
  };
});

const { items: projectTags } = useTagsV3Loader({
  params: projectTagsParams,
  count,
  pageSize,
});

const missingTagIds = shallowRef([]);
const missingTagsState = useTagsV3Loader({
  params: computed(() => ({
    ...projectTagsParams.value,
    ids: missingTagIds.value.join(','),
  })),
  count: computed(() => missingTagIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  projectTags,
  missingTagsState.items,
);

const matchAllTags = computed({
  get: () => normalizeFilterBoolean(activeFilter.value.parameters[props.matchAllTagsParam]),
  set(value) {
    activeFilter.value.parameters[props.matchAllTagsParam] = value;
  },
});

const tagIds = computed({
  get() {
    return typeof activeFilter.value.parameters[props.name] === 'string'
      ? activeFilter.value.parameters[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    activeFilter.value.parameters = {
      ...activeFilter.value.parameters,
      [props.name]: value.join(','),
      ...(props.showMatchAllTagsFilter ? { matchAllTags: value.length > 1 ? matchAllTags.value : false } : {}),
    };
  },
});

const shouldShowMatchAllTagsFilter = computed(() => props.showMatchAllTagsFilter && tagIds.value.length > 1);

const tags = computeAll(tagIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(tagIds), missingTagIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));
useFilterNormalize(toRef(props, 'matchAllTagsParam'), (value) => normalizeFilterBoolean(value));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));
useFilterClear(toRef(props, 'matchAllTagsParam'), shallowRef(false));

useFilterChips(
  computed(() =>
    tags.value.map((tag) => ({
      // color, name, etc
      name: tag.name,
      color: props.mode === 'exclude' ? null : tag.color,
      type: props.mode === 'exclude' ? 'excludeTag' : 'tag',
      delete: () => {
        tagIds.value = tagIds.value.filter((id) => id !== tag.id);
      },
    })),
  ),
);

useFilterCount(computed(() => tagIds.value.length));

function updateTagIds(newTags) {
  tagIds.value = newTags.map((tag) => tag.id);
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LswTagPicker
    :closeOnContentClick="false"
    location="bottom left"
    offset="12"
    :tags="tags"
    :projectId="projectId"
    :includeProjectTags="true"
    @update:tags="updateTagIds"
    @update:modelValue="toggleMenu"
  >
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="tags.length > 0"
        icon="lsi-tag"
        :data-identifier="`${dataIdentifierPrefix}-tags-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <template v-if="shouldShowMatchAllTagsFilter" #footer>
      <LscCheckbox v-model="matchAllTags" :label="t('Match all tags')" />
      <LscIcon
        v-if="matchAllTagsTooltip"
        v-LsdTooltip="matchAllTagsTooltip"
        size="sm"
        icon="lsi-tooltip"
        class="text-icon-subtle"
      />
    </template>
  </LswTagPicker>
</template>
