import { DateTime } from 'luxon';
import { useCustomReportActions, useFeatures, usePermissions, usePreferences } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypeIcons } from '@/module/customfield';
import { useReportTableColumns } from '../table/useReportTableColumns.js';
import { useReportTracking } from '../useReportTracking';
import { reportBuilderDatePeriods, reportBuilderFilterApiMapping } from './constants.js';
import { useReportBuilderFilters } from './useReportBuilderFilters.js';
import { useReportBuilderSettingsByType } from './useReportBuilderSettings.js';

const ReportBuilderSymbol = Symbol('useReportBuilder');

const reportDefaultParams = Object.freeze({
  id: null,
  name: '',
  type: 'project',
  columns: [],
  startAt: DateTime.now(),
  endAt: DateTime.now(),
  range: 'week',
  summary: false,
  users: [],
});

function camelCase(str) {
  return str
    .trim()
    .replace(/\s+(.)/g, (match, group1) => group1.toUpperCase())
    .replace(/^(.)/, (match, group1) => group1.toLowerCase());
}

export function ReportBuilder(existingReport) {
  const { filterParams, clearFilters } = useReportBuilderFilters();

  const { t } = useI18n();
  const { createCustomReport, patchCustomReport } = useCustomReportActions();

  const { canManageCustomfields } = usePermissions();
  const { advancedReportingEnabled, projectsWorkflowsEnabled } = useFeatures();
  const { reportsInAdvancedReporting } = usePreferences();
  const { trackCustomReportEvent } = useReportTracking();

  // reportColumns needs to be a full Ref to maintain the right reactivity
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  const reportColumns = ref([]);

  const maxNumberColumns = 20;

  const selectedColumns = computed(() => {
    return reportColumns.value.filter((column) => column.enabled).sort((a, b) => a.position - b.position);
  });

  const reachedMaxNumberColumns = computed(() => {
    return selectedColumns.value.length >= maxNumberColumns;
  });

  const reportBuilderAdvancedColumns = {
    user: {
      'job role': true,
      'available time': true,
      'estimated time': true,
      'estimated utilization': true,
      'actual utilization': true,
      'billable time': true,
      'billable utilization': true,
      'non billable utilization': true,
      'logged time': true,
      'logged time vs estimated time': true,
      'utilization percent target': true,
      'non billable time': true,
    },
    project: {
      owner: true,
      company: true,
      'total cost': true,
      'billable total': true,
      'profit number': true,
      'logged time': true,
      'date created': true,
      'profit percentage': true,
      'billable time': true,
      category: true,
    },
    milestone: {
      project: true,
      'due date': true,
    },
    task: {
      company: true,
      status: true,
      project: true,
      assignee: true,
      priority: true,
      'time logged': true,
    },
  };

  const reportBuilderColumnCategories = {
    general: [0, t('General')],
    'project and tasks': [1, t('Project and tasks')],
    'billing and time': [2, t('Billing and time')],
    'progress and time': [3, t('Progress and time')],
    utilization: [1, t('Utilization')],
    date: [5, t('Date')],
    'Custom fields': [6, t('Custom fields')],
  };

  const { items: columnMapping, columnTypeMap } = useReportTableColumns();

  // currentReport needs to be a full Ref to maintain the right reactivity
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  const currentReport = ref({
    ...reportDefaultParams,
  });

  const isAdvancedReporting = shallowRef(false);
  const isCustomReportBuilderCustomised = shallowRef(false);
  const hasInteractedWithReportBuilder = shallowRef(false);

  const currentReportDateRange = computed({
    get() {
      const { startAt: startDate, endAt: endDate, range } = currentReport.value;

      return {
        startDate,
        endDate,
        range,
      };
    },

    set(value) {
      currentReport.value = {
        ...currentReport.value,
        startAt: value.startDate,
        endAt: value.endDate,
        range: value.range,
      };
    },
  });

  function formatExistingReportUsers(grantAccessTo) {
    const formattedUsers = [];

    if (!grantAccessTo) {
      return formattedUsers;
    }

    Object.entries(grantAccessTo).forEach(([key, value]) => {
      if (!Array.isArray(value)) {
        return;
      }

      let entityType;
      switch (key) {
        case 'users':
          entityType = 'user';
          break;
        case 'teams':
          entityType = 'team';
          break;
        case 'companies':
          entityType = 'company';
          break;
        default:
          return;
      }

      const transformedUsers = value.map((item) => ({
        ...item,
        id: item[entityType].id,
        entityType,
      }));

      formattedUsers.push(...transformedUsers);
    });

    return formattedUsers;
  }

  function formatSingleColumn(column, index) {
    const { translation, icon, width, slot } = columnMapping[column.name.toLowerCase()] ?? {};
    const [groupIndex, category] = reportBuilderColumnCategories[column.category ?? 'general'];

    return {
      id: column.name.replace(/\s+/g, '').toLowerCase(),
      name: translation ?? column.name,
      rawName: column.name,
      icon,
      index,
      groupIndex,
      width: column.width ?? width ?? 150,
      align: 'left',
      category,
      enabled: false, // default to false, will be set to true if column is already in the report
      draggable: true,
      isCustomField: false,
      position: column.position ?? index,
      operator: column.operator ?? '',
      slot,
      itemKey: camelCase(column.name),
      type: columnTypeMap[column.name],
    };
  }

  const reportTypes = {
    project: {
      key: 'project',
      value: t('Project'),
      icon: 'lsi-project',
    },
    milestone: {
      key: 'milestone',
      value: t('Milestone'),
      icon: 'lsi-milestone',
    },
    task: {
      key: 'task',
      value: t('Task'),
      icon: 'lsi-task',
    },
    user: {
      key: 'user',
      value: t('User'),
      icon: 'lsi-user',
    },
  };

  const currentReportBuilderAdvancedColumns = computed(() => {
    return reportBuilderAdvancedColumns[currentReport.value.type];
  });

  const currentReportBuilderSettings = useReportBuilderSettingsByType(computed(() => currentReport.value.type));

  function formatReportColumns(settings) {
    if (!settings) {
      return [];
    }

    let formattedColumns = settings.columns?.map(formatSingleColumn);

    // Remove 'board' column if workflows enabled
    // 'workflowstages' column is only available if workflows enabled
    if (currentReport.value.type === 'task') {
      const columnToRemove = projectsWorkflowsEnabled.value ? 'board' : 'workflowstages';
      formattedColumns = formattedColumns.filter((column) => column.id !== columnToRemove);
    }

    // custom fields are only available for task and project reports
    if (!['task', 'project'].includes(currentReport.value.type)) {
      return formattedColumns;
    }

    const editable = canManageCustomfields.value;

    const [groupIndex, category] = reportBuilderColumnCategories['Custom fields'];

    const transformedCustomFields =
      settings.customfields?.map((customfield, index) => ({
        ...customfield,
        name: customfield.name,
        index: index + formattedColumns.length,
        position: customfield.position ?? index + formattedColumns.length,
        groupIndex,
        category,
        enabled: customfield.enabled || false,
        width: customfield.width ?? 140,
        icon: customfieldTypeIcons[customfield.type],
        draggable: true,
        isCustomField: true,
        editable,
      })) || [];

    return [...formattedColumns, ...transformedCustomFields];
  }

  function formatUsersWithPermissionToView(users) {
    return users.reduce(
      (acc, cur) => {
        const type = cur.entityType;

        if (type === 'team') {
          acc.teamIds.push(Number(cur.id));
        } else if (type === 'company') {
          acc.companyIds.push(Number(cur.id));
        } else {
          acc.userIds.push(Number(cur.id));
        }
        return acc;
      },
      { userIds: [], teamIds: [], companyIds: [] },
    );
  }

  function validateReportParams() {
    if (currentReport.value.name === '' || currentReport.value.name.length > 255 || !selectedColumns.value.length) {
      if (currentReport.value.name === '') {
        trackCustomReportEvent(
          'custom_report_modal',
          'custom_report_error_message_displayed',
          'advanced',
          'A custom report must have a name',
        );
      }
      if (currentReport.value.name.length > 255) {
        trackCustomReportEvent(
          'custom_report_modal',
          'custom_report_error_message_displayed',
          'advanced',
          'A custom report name must be less than 255 characters',
        );
      }
      if (!selectedColumns.value.length) {
        trackCustomReportEvent(
          'custom_report_modal',
          'custom_report_error_message_displayed',
          'advanced',
          'A custom report must have at least one column added',
        );
      }
      return false;
    }

    return true;
  }

  function transformFilterValue(value, convertToInt) {
    if (typeof value === 'string') {
      if (value.trim() === '') {
        return convertToInt ? [] : [value];
      }
      if (convertToInt) {
        return value.split(',').map(Number);
      }
      return value.split(',').map((item) => item.trim());
    }
    return value;
  }

  async function updateFilterParams({ filters, baseType, customfieldFilters }) {
    const typeMap = reportBuilderFilterApiMapping[baseType];
    const reversedTypeMap = Object.entries(typeMap).reduce((acc, [key, value]) => {
      acc[value.filterName] = {
        key,
        ...value,
      };
      return acc;
    }, {});

    const mapped = {};

    for (const filter of filters || []) {
      const mapping = reversedTypeMap[filter.name];
      const value = Array.isArray(filter.value) ? filter.value.map(({ id }) => id).join(',') : filter.value;

      if (mapping && value !== undefined && value !== null) {
        mapped[mapping.key] = value;
      } else if (value !== undefined && value !== null) {
        mapped[filter.name] = value;
      }
    }

    for (const filter of customfieldFilters || []) {
      mapped[`customField[${filter.id}][${filter.operator}]`] = filter.value;
    }

    await nextTick();

    filterParams.value = {
      ...filterParams.value,
      ...mapped,
    };
  }

  function formatFilters() {
    const filters = [];
    const customFieldFilters = [];
    const params = filterParams.value;
    const reportTypeMap = reportBuilderFilterApiMapping[currentReport.value.type];

    Object.keys(params).forEach((key) => {
      const mapping = reportTypeMap[key];
      if (mapping) {
        filters.push({
          name: mapping.filterName,
          value: transformFilterValue(params[key], mapping.convertToInt),
        });
      } else if (key.startsWith('customField[')) {
        let value = params[key];

        if (value === null) {
          return;
        }

        value = typeof value === 'boolean' ? String(value) : value;

        const match = key.match(/\[(.*?)\]/g);
        const splitKey = match.map((matched) => matched.slice(1, -1));

        customFieldFilters.push({
          id: Number(splitKey[0]),
          operator: splitKey[1],
          value,
        });
      } else {
        filters.push({
          name: key,
          value: params[key],
        });
      }
    });

    return {
      filters,
      customFieldFilters,
    };
  }

  async function saveReport(copy = false) {
    if (!validateReportParams()) {
      return false;
    }

    const currentColumms = selectedColumns.value;
    const columns = [];
    const customFields = [];

    for (let index = 0; index < currentColumms.length; index++) {
      const column = currentColumms[index];
      const position = index + 1;

      if (column.isCustomField) {
        customFields.push({
          position,
          id: column.id,
        });
      } else {
        columns.push({
          position,
          name: column.rawName,
        });
      }
    }

    const payload = {
      baseType: currentReport.value.type,
      columns,
      customFields,
      datePeriod: reportBuilderDatePeriods[currentReport.value.range],
      endAt: currentReport.value.endAt.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      grantAccessTo: formatUsersWithPermissionToView(currentReport.value.users),
      name: currentReport.value.name,
      sortByPosition: -1,
      sortOrder: 'asc',
      startAt: currentReport.value.startAt.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      summary: currentReport.value.summary,
      ...formatFilters(),
    };

    const filterParamsKeys = Object.keys(filterParams.value);
    const filteredKeys = filterParamsKeys
      .filter((key) => {
        const value = filterParams.value[key];
        return (
          value !== undefined &&
          value !== null &&
          value !== '' &&
          value !== false &&
          (!Array.isArray(value) || value.length > 0)
        );
      })
      .map((key) => (key.includes('customField[') ? 'custom_fields' : key));

    const uniqueKeys = [...new Set(filteredKeys)];
    const keysString = uniqueKeys.join(' | ');

    if (existingReport.value?.id && !copy) {
      trackCustomReportEvent(
        'edit_custom_report_modal',
        'update_report_clicked',
        'activation',
        payload.baseType,
        currentReport.value.range,
        keysString,
        payload.grantAccessTo.userIds.length ?? 0,
      );
      const response = await patchCustomReport(existingReport.value.id, payload);

      if (advancedReportingEnabled.value) {
        reportsInAdvancedReporting.value = {
          ...reportsInAdvancedReporting.value,
          [Number(existingReport.value.id)]: isAdvancedReporting.value,
        };
      }

      return response;
    }

    const response = await createCustomReport(payload);

    if (response.status === 200) {
      trackCustomReportEvent(
        'custom_report_modal',
        'custom_report_created',
        'activation',
        payload.baseType,
        currentReport.value.range,
        keysString,
        payload.grantAccessTo.userIds.length ?? 0,
      );
    }

    if (advancedReportingEnabled.value) {
      const {
        data: { customReport },
      } = response;

      reportsInAdvancedReporting.value = {
        ...reportsInAdvancedReporting.value,
        [Number(customReport.id)]: isAdvancedReporting.value,
      };
    }

    return response;
  }

  const hasColumns = computed(() => {
    return selectedColumns.value.length > 0;
  });

  watch(
    existingReport,
    (report) => {
      if (!report?.id) {
        return;
      }

      nextTick(() => {
        clearFilters();
      });

      const {
        id,
        name,
        startAt,
        endAt,
        datePeriod,
        filters,
        baseType: type,
        customfieldFilters,
        columns,
        customfields,
        grantAccessTo,
        summary,
      } = report;

      currentReport.value = {
        id,
        name,
        type,
        summary,
        columns,
        customfields,
        filters,
        customfieldFilters,
        startAt: DateTime.fromISO(startAt),
        endAt: DateTime.fromISO(endAt),
        range:
          Object.keys(reportBuilderDatePeriods).find((key) => reportBuilderDatePeriods[key] === datePeriod) ?? 'week',
        users: formatExistingReportUsers(grantAccessTo),
      };
      isAdvancedReporting.value = reportsInAdvancedReporting.value[Number(id)] ?? false;
      triggerRef(isAdvancedReporting);
      updateFilterParams(report);
    },
    {
      flush: 'sync',
      immediate: true,
    },
  );

  watch(
    [currentReportBuilderSettings, existingReport],
    ([newVal, report]) => {
      const formattedColumns = formatReportColumns(newVal);
      const enabled = new Map();
      const position = new Map();

      if (reportColumns.value.length) {
        let pos = 0;
        for (const column of reportColumns.value) {
          enabled.set(column.id, column.enabled);
          position.set(column.id, ++pos);
        }
      }

      if (!report?.id) {
        reportColumns.value = formattedColumns
          .map((column) => {
            return {
              ...column,
              enabled: Boolean(enabled.has(column.id) ? enabled.get(column.id) : false),
              position: position.has(column.id) ? position.get(column.id) : column.position,
            };
          })
          .sort((a, b) => a.position - b.position);
        return;
      }

      const columns = {};

      for (const column of report.columns || []) {
        columns[column.name] = column;
      }

      for (const column of report.customfields || []) {
        columns[column.id] = column;
      }

      reportColumns.value = formattedColumns
        .map((column) => {
          return {
            ...column,
            enabled: Boolean(enabled.has(column.id) ? enabled.get(column.id) : columns[column.rawName ?? column.id]),
            position: position.has(column.id)
              ? position.get(column.id)
              : ((columns[column.rawName] ?? columns[column.id])?.position ?? column.position),
          };
        })
        .sort((a, b) => a.position - b.position);
    },
    {
      flush: 'post',
      immediate: true,
    },
  );

  watch(
    () => currentReport.value?.type,
    async (type, prevType) => {
      if (currentReport.value.id && (!prevType || type === prevType)) {
        return;
      }

      await nextTick();

      clearFilters();
    },
    {
      immediate: true,
    },
  );

  watch(
    [isAdvancedReporting, currentReportBuilderAdvancedColumns, reportColumns],
    async ([flag, advancedColumns, _reportColumns]) => {
      if (!advancedReportingEnabled.value) {
        return;
      }

      await nextTick();

      if (flag && advancedColumns) {
        for (const column of _reportColumns) {
          if (advancedColumns[column.rawName]) {
            column.enabled = true;
          }
        }
      }
    },
    {
      immediate: true,
      flush: 'post',
    },
  );

  const unwatch = watch(
    [currentReport, filterParams, isAdvancedReporting, reportColumns],
    () => {
      // ignore changes if the user has not interacted with the report builder
      if (hasInteractedWithReportBuilder.value) {
        isCustomReportBuilderCustomised.value = true;
        unwatch();
      }
    },
    {
      deep: true,
    },
  );

  return {
    reportColumns,
    hasColumns,
    currentReport,
    currentReportBuilderSettings,
    selectedColumns,
    reachedMaxNumberColumns,
    reportTypes,
    currentReportDateRange,
    saveReport,
    validateReportParams,
    advancedReportingEnabled,
    isAdvancedReporting,
    currentReportBuilderAdvancedColumns,
    reportBuilderColumnCategories,
    formatSingleColumn,
    isCustomReportBuilderCustomised,
    hasInteractedWithReportBuilder,
  };
}

export function provideReportBuilder(existingReport) {
  const reportBuilder = ReportBuilder(existingReport);
  provide(ReportBuilderSymbol, reportBuilder);
  return reportBuilder;
}

export function useReportBuilder() {
  return inject(ReportBuilderSymbol);
}
