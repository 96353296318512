<script setup>
import { useTasksV3Loader } from '@/api';
import { useI18n } from '@/util';
import ReportTableCustomReport from '../../table/ReportTableCustomReport.vue';
import { reportBuilderSampleData, reportBuilderSampleDataTotalRow } from '../constants.js';
import { useReportBuilder } from '../useReportBuilder.js';
import { useReportBuilderFilters } from '../useReportBuilderFilters.js';

const { t } = useI18n();
const { currentReport, reportColumns } = useReportBuilder();
const { filterParams } = useReportBuilderFilters();
const count = 10;
const pageSize = 10;
const state = useTasksV3Loader({
  params: computed(() => ({
    checkForReminders: true,
    endDate: currentReport.value.endAt.toFormat('yyyy-MM-dd'),
    include:
      'projects,projects.categories,tasklists,companies,teams,users,tags,cards,cards.columns,taskSequences,lockdowns,lockdowns.users,lockdowns.companies,lockdowns.teams,lockdowns.users.companies,lockdowns.companies.users,lockdowns.teams.users,taskLists,taskListNames,projectNames,workflows,workflows.stages',
    includeCustomFields: true,
    includeOriginalDueDate: true,
    showCompletedLists: filterParams.value.includeCompletedTasks,
    includeCompletedTasks: filterParams.value.includeCompletedTasks,
    includeArchivedProjects: filterParams.value.includeArchivedProjects,
    orderBy: 'manual',
    orderMode: 'asc',
    projectCategoryIds: filterParams.value.categoryIds,
    projectCompanyIds: filterParams.value.companyIds,
    projectIds: filterParams.value.projectIds,
    startDate: currentReport.value.startAt.toFormat('yyyy-MM-dd'),
    status: filterParams.value.status,
    tagIds: filterParams.value.tagIds,
    responsiblePartyIds: filterParams.value.userIds,
  })),
  count,
  pageSize,
});

const { items: tasks } = state;

const tasksWithSampleData = computed(() => {
  return tasks.value?.map((task, index) => ({
    ...reportBuilderSampleData[index],
    ...task,
    ...task.customfieldValues,
    workflowStages: task.workflowStages?.map((workflow) => workflow.stage).filter((stage) => stage),
    board: task.column ? [{ name: task.column.name, color: task.column.color }] : null,
    company: { name: task.project.company.name },
    dateCreated: task.createdAt,
    private: task.isPrivate,
    repeats: task.sequence,
    taskList: task.taskList.name,
    task: task.name,
  }));
});

const customfieldsMap = computed(() =>
  tasksWithSampleData.value.reduce(
    (acc, task) => ({
      ...acc,
      ...task.customfieldValues,
    }),
    {},
  ),
);

const reportTypeColumn = {
  id: 'taskName',
  name: t('Task'),
  enabled: true,
  draggable: false,
  width: 200,
  align: 'left',
};

const tableColumns = computed({
  get() {
    const customfields = customfieldsMap.value;

    return [
      reportTypeColumn,
      ...reportColumns.value.map((column) =>
        column.isCustomField
          ? {
              ...column,
              choices:
                customfields[column.id]?.options?.choices?.reduce((acc, choice) => {
                  acc[choice.value] = choice;
                  return acc;
                }, {}) ?? {},
            }
          : column,
      ),
    ];
  },
  set(_columns) {
    reportColumns.value = _columns.filter((column) => column.id !== 'taskName');
  },
});
</script>

<template>
  <WidgetLoadingState :state="state">
    <template #defaultOrLoading>
      <ReportTableCustomReport
        v-model:columns="tableColumns"
        :items="tasksWithSampleData"
        :summaryRowData="reportBuilderSampleDataTotalRow"
        :showTotalRow="currentReport.summary"
      />
    </template>
    <template #blank>
      <LscEmptyState
        class="h-full"
        size="lg"
        :title="t('There are no tasks that match your criteria')"
        :message="t('Please adjust your date range or filter settings and try again')"
      />
    </template>
  </WidgetLoadingState>
</template>
