<script setup>
import { DateTime } from 'luxon';
import { useCurrentAccount } from '@/api';
import { useI18n } from '@/util';
import { TagList } from '@/module/tag';
import { TaskRepeatCardTitle } from '@/module/task';
import ReportCellColorDotText from '../cell/ReportCellColorDotText.vue';
import ReportCellColored from '../cell/ReportCellColored.vue';
import ReportCellHealth from '../cell/ReportCellHealth.vue';
import ReportCellProjectBudget from '../cell/ReportCellProjectBudget.vue';
import { useReportHelpers } from '../useReportHelpers';
import ReportTableCustomReportTotalRow from './ReportTableCustomReportTotalRow.vue';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  summaryRowData: {
    type: Object,
    default: () => ({}),
  },
  showTotalRow: {
    type: Boolean,
    default: false,
  },
  showQuickviews: {
    type: Boolean,
    default: false,
  },
});

const columns = defineModel('columns', {
  type: Array,
  default: () => [],
});

const order = defineModel('order', {
  type: Object,
  default: () => null,
});

const { formatCurrency, formatDate, formatPercentage, t, formatMinutes } = useI18n();
const {
  getUtilisationCellVariant,
  getAssigneeAvatars,
  getDifference,
  getPriorityOptions,
  getProjectHealthOptions,
  getProgressCellVariant,
  getProfitCellVariant,
  getRemainingTasksText,
  getStatusOptions,
  getTaskCompletionVariant,
  getTaskListNames,
  getTimeLeft,
  getTimeLeftDotColor,
  getProjectDates,
  hasAssignees,
  openQuickView,
} = useReportHelpers();

const account = useCurrentAccount();

const healthOptions = computed(() => getProjectHealthOptions());
const accountCurrencySymbol = computed(() => account.value?.currency.symbol ?? '$');

function getDates(item) {
  const start = item.start ?? item.dates?.start;
  const end = item.end ?? item.dates?.end;
  return getProjectDates(start, end);
}

function getStatus(item) {
  const { status, dueDate, completedDate } = item.status && typeof item.status === 'object' ? item.status : item;
  return getStatusOptions(status, dueDate, completedDate);
}

function handleColumnUpdate(updatedColumns) {
  const updateMap = new Map(updatedColumns.map((column, index) => [column.id, { ...column, index }]));
  const columnsValue = columns.value;

  for (const column of columnsValue) {
    if (updateMap.has(column.id)) {
      const updated = updateMap.get(column.id);
      column.width = updated.width;
      column.position = column.draggable ? updated.index + 1 : column.position;
    }
  }

  columns.value = columnsValue.toSorted((a, b) => a.position - b.position);
}
</script>

<template>
  <LscTable
    v-model:order="order"
    :columns="columns"
    class="h-full place-content-start overflow-auto overscroll-y-auto overscroll-x-none scrollbar-lg"
    density="lg"
    stickyHeader
    stickyFirstColumn
    dividers
    :stickyFooter="showTotalRow"
    data-identifier="report-builder-preview-table"
    @update:columns="handleColumnUpdate"
  >
    <LscTableRow v-for="item in items" :key="item.id">
      <template #customfield-currency="{ column }">
        <LscOverflowEllipsis v-if="item[column.id]?.value != null">
          {{ formatCurrency(item[column.id]?.value) }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-duration="{ column }">
        <LscOverflowEllipsis v-if="item[column.id]?.value != null">
          {{ formatMinutes(item[column.id]?.value) }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-currency-per-duration="{ column }">
        <LscOverflowEllipsis v-if="item[column.id]?.value != null">
          {{ formatCurrency(item[column.id]?.value) }}/h
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-duration-per-currency="{ column }">
        <LscOverflowEllipsis v-if="item[column.id]?.value != null">
          {{ formatMinutes(item[column.id]?.value) }}/{{ accountCurrencySymbol }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-text="{ column }">
        <LscOverflowEllipsis v-if="item[column.id]?.value != null">
          {{ item[column.id]?.value }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-dropdown="{ column }">
        <ReportCellColorDotText
          v-if="item[column.id]?.value != null"
          :text="item[column.id]?.value"
          :color="column.choices?.[item[column.id]?.value]?.color"
        />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-date="{ column }">
        <LscDate v-if="item[column.id]?.value != null" :dateTime="DateTime.fromISO(item[column.id]?.value)" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-link="{ column }">
        <LscLink v-if="item[column.id]?.value != null" :href="item[column.id]?.value" target="_blank">
          <LscOverflowEllipsis>{{ item[column.id]?.value }}</LscOverflowEllipsis>
        </LscLink>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #customfield-checkbox="{ column }">
        <LscIcon :icon="item[column.id]?.value ? 'lsi-customfield-checkbox' : 'lsi-checkbox-default'" />
      </template>
      <template #projectName>
        <LscOverflowEllipsis
          class="font-semibold"
          :class="{ 'cursor-pointer': showQuickviews }"
          @click="showQuickviews ? openQuickView('project', item.projectId, item.project) : null"
        >
          {{ item.project?.name || item.project }}
        </LscOverflowEllipsis>
      </template>
      <template #milestoneName>
        <LscOverflowEllipsis
          class="font-semibold"
          :class="{ 'cursor-pointer': showQuickviews }"
          @click="showQuickviews ? openQuickView('milestone', item.milestoneId) : null"
        >
          {{ item.milestone || item.name }}
        </LscOverflowEllipsis>
      </template>
      <template #userName>
        <div class="flex items-center gap-3 overflow-hidden">
          <LscAvatar :src="item.user.avatarUrl" class="shrink-0" size="md" />
          <LscOverflowEllipsis class="font-semibold">{{ item.user.name }}</LscOverflowEllipsis>
        </div>
      </template>
      <template #taskName>
        <LscOverflowEllipsis
          class="font-semibold"
          :class="{ 'cursor-pointer': showQuickviews }"
          @click="showQuickviews ? openQuickView('task', item.taskId, item.task) : null"
        >
          {{ item.task?.name || item.task }}
        </LscOverflowEllipsis>
      </template>
      <template #activeprojects>
        <LscOverflowEllipsis v-if="item.activeProjects">
          {{ item.activeProjects }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #actualutilization>
        <LscTableCellChart
          :percentage="item.actualUtilization"
          :variant="getUtilisationCellVariant(item.actualUtilization)"
        >
          {{ formatPercentage(item.actualUtilization / 100) }}
        </LscTableCellChart>
      </template>
      <template #assignee>
        <div v-if="item.assignees || item.assignee">
          <LscAvatarStack
            v-if="item.assignees?.length || hasAssignees(item.assignee)"
            :avatars="getAssigneeAvatars(item.assignees || item.assignee)"
            size="md"
          />
          <LscOverflowEllipsis v-else>Anyone</LscOverflowEllipsis>
        </div>
        <LscAvatarStack
          v-else-if="item.responsibleParties"
          :avatars="getAssigneeAvatars(item.responsibleParties)"
          size="md"
        />
      </template>
      <template #assignedtasks>
        <LscOverflowEllipsis v-if="item.assignedTasks">{{ item.assignedTasks }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #availabletime>
        <LscOverflowEllipsis v-if="item.availableTime">{{ formatMinutes(item.availableTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #board="">
        <div v-if="item.board?.length" class="inline-flex gap-2 overflow-x-auto">
          <ReportCellColorDotText
            v-for="board in item.board"
            :key="board.name"
            :text="board.column ? board.column.name : board.name"
            :color="board.column?.color || board.color"
          />
        </div>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #workflowstages>
        <div v-if="item.workflowStages?.length" class="inline-flex gap-2 overflow-x-auto">
          <ReportCellColorDotText
            v-for="stage in item.workflowStages"
            :key="stage.name"
            :text="stage.name"
            :color="stage.color"
          />
        </div>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #billabletime>
        <LscOverflowEllipsis v-if="item.billableTime">{{ formatMinutes(item.billableTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #billabletotal>
        <LscOverflowEllipsis v-if="item.billableTotal">{{ formatCurrency(item.billableTotal) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #billableutilization>
        <LscTableCellChart
          :percentage="item.billableUtilization"
          :variant="getUtilisationCellVariant(item.billableUtilization)"
        >
          {{ formatPercentage(item.billableUtilization / 100) }}
        </LscTableCellChart>
      </template>
      <template #budget>
        <ReportCellProjectBudget
          v-if="item.budget"
          :project="{ id: item.projectId ?? item.project?.id, budget: item.budget }"
          :showOnHover="false"
          :canEdit="false"
        />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #category>
        <ReportCellColorDotText v-if="item.category" :text="item.category.name" :color="item.category.color" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #company>
        <LscOverflowEllipsis v-if="item.company?.name">{{ item.company.name }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #completedby>
        <LscAvatar v-if="item.completedBy" v-bind="getLsAvatarProps({ user: item.completedBy })" size="md" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #completedtasks>
        <LscOverflowEllipsis v-if="item.completedTasks">{{ item.completedTasks }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #createdby>
        <LscAvatar v-if="item.createdBy" v-bind="getLsAvatarProps({ user: item.createdBy })" size="md" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #dates>
        <LscOverflowEllipsis v-if="item.dates || item.start || item.end">
          {{ getDates(item) }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #description>
        <LscOverflowEllipsis v-if="item.description">{{ item.description }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #difference>
        <LscOverflowEllipsis
          :class="
            getDifference(item.dueDate, item.originalDueDate, item.completedDate).difference < 0
              ? 'text-critical'
              : 'text-success'
          "
        >
          {{ getDifference(item.dueDate, item.originalDueDate, item.completedDate).text }}
        </LscOverflowEllipsis>
      </template>
      <template #duedate>
        <LscOverflowEllipsis v-if="item.dueDate">{{ formatDate(item.dueDate) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #datecreated>
        <LscOverflowEllipsis v-if="item.dateCreated">{{ formatDate(item.dateCreated) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #estimatedtime>
        <LscOverflowEllipsis v-if="item.estimatedTime">{{ formatMinutes(item.estimatedTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #estimatedutilization>
        <LscTableCellChart
          :percentage="item.estimatedUtilization"
          :variant="getUtilisationCellVariant(item.estimatedUtilization)"
        >
          {{ formatPercentage(item.estimatedUtilization / 100) }}
        </LscTableCellChart>
      </template>
      <template #health>
        <ReportCellHealth :statusId="item.health" :options="healthOptions" isReadOnly />
      </template>
      <template #id>
        <LscOverflowEllipsis v-if="item.id">{{ item.id }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #lastactive>
        <LscOverflowEllipsis v-if="item.lastActive">{{ formatDate(item.lastActive) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #lastupdated>
        <LscOverflowEllipsis v-if="item.lastUpdated">{{ formatDate(item.lastUpdated) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #latestupdate>
        <LscOverflowEllipsis v-if="item.latestUpdate">{{ item.latestUpdate.update }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #loggedtime>
        <LscOverflowEllipsis v-if="item.loggedTime">{{ formatMinutes(item.loggedTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #loggedtimevsestimatedtime>
        <LscOverflowEllipsis v-if="item.loggedTimeVsEstimatedTime">
          {{ formatMinutes(item.loggedTimeVsEstimatedTime) }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #nonbillabletime>
        <LscOverflowEllipsis v-if="item.nonBillableTime">{{ formatMinutes(item.nonBillableTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #nonbillableutilization>
        <LscTableCellChart
          :percentage="item.nonBillableUtilization"
          :variant="getUtilisationCellVariant(item.nonBillableUtilization)"
        >
          {{ formatPercentage(item.nonBillableUtilization / 100) }}
        </LscTableCellChart>
      </template>
      <template #originalduedate>
        <LscOverflowEllipsis v-if="item.originalDueDate">{{ formatDate(item.originalDueDate) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #owner>
        <LscAvatar v-if="item.owner" v-bind="getLsAvatarProps({ user: item.owner })" size="md" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #overduetasks>
        <LscOverflowEllipsis v-if="item.overdueTasks">{{ item.overdueTasks }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #percentagetaskscompleted>
        <LscTableCellChart
          :percentage="item.percentageTasksCompleted"
          :variant="getTaskCompletionVariant(item.percentageTasksCompleted)"
        >
          <div class="flex flex-col">
            <LscOverflowEllipsis>{{ formatPercentage(item.percentageTasksCompleted / 100) }}</LscOverflowEllipsis>
            <LscOverflowEllipsis>{{ getRemainingTasksText(item.numberOfActiveTasks) }}</LscOverflowEllipsis>
          </div>
        </LscTableCellChart>
      </template>
      <template #priority>
        <ReportCellColored :background="getPriorityOptions(item.priority).background">
          <template #center>
            <LscOverflowEllipsis class="ml-auto mr-auto font-semibold">
              {{ getPriorityOptions(item.priority).label }}
            </LscOverflowEllipsis>
          </template>
        </ReportCellColored>
      </template>
      <template #private>
        <div v-if="item.private" class="inline-flex items-center gap-1">
          <LscIcon icon="lsi-privacy" class="text-icon-subtle" />
          {{ t('Private') }}
        </div>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #progress>
        <LscTableCellChart :percentage="item.progress" :variant="getProgressCellVariant(item.progress)">
          {{ formatPercentage(item.progress / 100) }}
        </LscTableCellChart>
      </template>
      <template #project>
        <LscOverflowEllipsis
          v-if="item.project"
          :class="{ 'cursor-pointer': showQuickviews }"
          @click="showQuickviews ? openQuickView('project', item.projectId, item.project) : null"
        >
          {{ item.project?.name ?? item.project }}
        </LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #profitnumber>
        <LscTableCellChart :percentage="100" :variant="getProfitCellVariant(item.profitNumber)">
          {{ formatCurrency(item.profitNumber) }}
        </LscTableCellChart>
      </template>
      <template #reminders>
        <LscIcon v-if="item.reminders" class="text-icon-info" size="sm" icon="lsi-mark-all-read" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #repeats>
        <TaskRepeatCardTitle v-slot="{ title }" :sequence="item.repeats || item.sequence">
          <LscOverflowEllipsis>
            {{ title }}
          </LscOverflowEllipsis>
        </TaskRepeatCardTitle>
      </template>
      <template #startdate>
        <LscOverflowEllipsis v-if="item.startDate">{{ formatDate(item.startDate) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #status>
        <ReportCellColored v-if="item.dueDate || item.status.dueDate" :background="getStatus(item).background">
          <template #left>
            <LscOverflowEllipsis class="mr-auto font-semibold">
              {{ getStatus(item).label }}
            </LscOverflowEllipsis>
          </template>
          <template #right>
            <LscOverflowEllipsis class="ml-auto">
              {{ getStatus(item).statusDate }}
            </LscOverflowEllipsis>
          </template>
        </ReportCellColored>
        <LscOverflowEllipsis v-else>{{ t('No due date') }}</LscOverflowEllipsis>
      </template>
      <template #tags>
        <TagList v-if="item.tags" :tags="item.tags" :canEdit="false" limit="auto" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #teams>
        <LscAvatarStack v-if="item.teams?.length" :avatars="item.teams" size="md" />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #taskcompletion>
        <LscTableCellChart :percentage="item.taskCompletion" :variant="getTaskCompletionVariant(item.taskCompletion)">
          <div class="flex flex-col">
            <div>{{ formatPercentage(item.taskCompletion / 100) }}</div>
            <div class="font-normal">
              {{ getRemainingTasksText(item.numberOfActiveTasks) }}
            </div>
          </div>
        </LscTableCellChart>
      </template>
      <template #tasklist>
        <LscOverflowEllipsis v-if="item.taskList">{{ item.taskList }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #tasklists>
        <LscOverflowEllipsis v-if="item.taskLists?.length">{{ getTaskListNames(item.taskLists) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #tasktotal>
        <LscOverflowEllipsis v-if="item.taskTotal">{{ item.taskTotal }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #timeestimate>
        <LscOverflowEllipsis v-if="item.timeEstimate">{{ formatMinutes(item.timeEstimate) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #timeleft>
        <ReportCellColorDotText
          v-if="item.timeLeft"
          :text="getTimeLeft(item.timeLeft, item.subStatus === 'completed')"
          :color="getTimeLeftDotColor(item.timeLeft)"
        />
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #timelogged>
        <LscOverflowEllipsis v-if="item.timeLogged">{{ formatMinutes(item.timeLogged) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #totalcost>
        <LscOverflowEllipsis v-if="item.totalCost">{{ formatCurrency(item.totalCost) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>
      <template #unavailabletime>
        <LscOverflowEllipsis v-if="item.unavailableTime">{{ formatMinutes(item.unavailableTime) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>

      <template #jobrole>
        <LscOverflowEllipsis v-if="item.jobRole">{{ item.jobRole }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>

      <template #percentage="{ column }">
        <LscTableCellChart
          :percentage="item[column.itemKey] || 0"
          :variant="getUtilisationCellVariant(item[column.itemKey] || 0)"
        >
          {{ formatPercentage((item[column.itemKey] || 0) / 100) }}
        </LscTableCellChart>
      </template>

      <template #time="{ column }">
        <LscOverflowEllipsis v-if="item[column.itemKey]">{{ formatMinutes(item[column.itemKey]) }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>

      <template #integer="{ column }">
        <LscOverflowEllipsis v-if="item[column.itemKey]">{{ item[column.itemKey] }}</LscOverflowEllipsis>
        <LscTableCellEmpty v-else disabled />
      </template>

      <template #forecastprofitnumber>
        {{ formatCurrency(item.forecastProfitNumber || 0) }}
      </template>

      <template #forecastbillabletotal>
        {{ formatCurrency(item.forecastBillableTotal || 0) }}
      </template>

      <template #forecastcost>
        {{ formatCurrency(item.forecastCost || 0) }}
      </template>
    </LscTableRow>
    <slot />

    <template v-if="showTotalRow" #footer>
      <ReportTableCustomReportTotalRow :item="summaryRowData" />
    </template>
  </LscTable>
</template>
