import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-24-20
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-a-24-20-differentiated-onboarding-for-icp-20-plus/targeting?env=production&env=staging&selected-env=production
 * https://digitalcrew.teamwork.com/spaces/growth/page/59525-a-24-20-differentiated-onboarding-for-icp-20
 */

export function useExperimentA2420() {
  const { isCompanySizeAboveTwenty, isPlanDeliver } = useCohort();
  const { differentiatedOnboardingForIcp20PlusEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = differentiatedOnboardingForIcp20PlusEnabled;

  const appLevelTargeting = computed(() => isCompanySizeAboveTwenty.value && !isPlanDeliver.value);
  const isExpA2420Variation = computed(
    () => differentiatedOnboardingForIcp20PlusEnabled.value && appLevelTargeting.value,
  );

  function trackExperimentA2420() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      appLevelTargeting,
      ignoreInExperimentOnRuleMatch: true,
    });
  }

  return {
    isExpA2420Variation,
    trackExperimentA2420,
  };
}
