/* eslint-disable no-underscore-dangle */
import { debouncedWatch } from '@vueuse/core';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { inboxFiltersMatchItem } from './inboxFiltersMatchItem';

function isString(value) {
  return typeof value === 'string';
}

function isPlainObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

function normalizeInboxItem(i, users) {
  const entity = (() => {
    // Special case - for the event `user-addedtoproject`, the underlying entity is `user`
    // however in terms of Inbox and the preview pane, the expected entity is `project`,
    // so we need to manipulate the data to achieve this
    if (i.event.toLowerCase() === 'user-addedtoproject') {
      const match = i.entity.link.match(/^\/?projects\/(\d+)/);

      const id = parseInt(match?.[1], 10);
      if (!id) {
        return i.entity;
      }

      return {
        ...i.entity,
        id,
        type: 'project',
      };
    }

    if (i.entity.type.toLowerCase() === 'file') {
      const match = i.entity.link.match(/files\/(\d+)/);

      const id = parseInt(match?.[1], 10);
      if (!id) {
        return i.entity;
      }

      return {
        ...i.entity,
        id,
        type: 'file',
      };
    }

    const type = (() => {
      return i?.entity?.type === 'comment' ? 'message_comment' : i.entity.type;
    })();

    const extra = (() => {
      const info = (() => {
        // `extra.info` is either an object or a JSON-encoded string
        if (!i.entity?.extra?.info || (!isString(i.entity?.extra?.info) && !isPlainObject(i.entity?.extra?.info))) {
          return undefined;
        }

        try {
          return isString(i.entity?.extra?.info) ? JSON.parse(i.entity?.extra?.info) : { ...i.entity?.extra?.info };
        } catch (error) {
          return undefined;
        }
      })();

      return {
        ...i.entity.extra,
        info,
      };
    })();

    return {
      ...i.entity,
      type,
      extra,
    };
  })();

  return {
    ...i,
    entity,
    user: users[i.createdBy],
  };
}

function responseToItems({ data: { items = [], included: { users = {} } = {} } = {} } = {}) {
  return items.map((i) => normalizeInboxItem(i, users));
}

function responseToMeta({ data: { meta = {} } = {} } = {}) {
  return {
    ...meta,
    nextCursor: meta.nextCursor || null,
    prevCursor: meta.prevCursor || null,
  };
}

function order(itemA, itemB) {
  return new Date(itemB.createdAt) - new Date(itemA.createdAt);
}

function Loader({ params: _params = {}, pageSize: _pageSize = 40 }) {
  const axiosInstance = useAxios();

  const params = shallowRef(_params);
  const itemsMap = shallowRef(new Map());
  const pageSize = shallowRef(_pageSize);
  const cursor = shallowRef(undefined);
  const meta = shallowRef(undefined);
  const response = shallowRef(undefined);
  const error = shallowRef(undefined);
  const loading = shallowRef(false);
  const initialized = computed(() => cursor.value !== undefined);
  const allLoaded = computed(() => cursor.value === null);
  const lastUpdated = shallowRef(undefined);

  const abortController = ref(null);

  const items = computed(() => {
    const _items = Array.from(itemsMap.value.values());

    if (params.value.searchTerm) {
      return _items;
    }

    return _items.sort(order);
  });

  function getLastUpdated({ headers }) {
    // Subtract 10s from the response date for extra safety
    return DateTime.fromHTTP(headers.date, { zone: 'utc' }).minus(10000);
  }

  async function _fetch(__params, isWebsocket = false) {
    if (loading.value) {
      return; // already performing a request
    }

    try {
      loading.value = true;

      abortController.value = new AbortController();

      response.value = await axiosInstance.get(
        '/projects/api/v3/inbox/items.json',
        {
          params: __params,
          signal: abortController.value.signal,
        },
        {
          headers: {
            'Triggered-By': isWebsocket ? 'event' : 'user',
            'Sent-By': 'composable',
            twProjectsVer: window.appVersionId ?? '',
          },
        },
      );

      error.value = undefined;

      meta.value = responseToMeta(response.value);

      responseToItems(response.value).forEach((item) => {
        return itemsMap.value.set(item.id, item);
      });

      triggerRef(itemsMap);

      lastUpdated.value = getLastUpdated(response.value);
    } catch (_error) {
      if (axios.isCancel(_error)) {
        return;
      }

      if (!axios.isAxiosError(_error)) {
        throw _error;
      }

      response.value = undefined;
      error.value = _error;
    } finally {
      loading.value = false;
    }
  }

  async function loadMore() {
    if (pageSize.value <= 0) {
      return; // cannot load anything more
    }

    if (allLoaded.value) {
      return; // cannot load anything more
    }

    await _fetch({
      ...params.value,
      cursor: cursor.value || '',
      limit: pageSize.value,
    });

    cursor.value = meta.value?.nextCursor;
  }

  async function loadUpdates() {
    if (lastUpdated.value === undefined) {
      return;
    }

    await _fetch(
      {
        ...params.value,
        updatedAfter: lastUpdated.value.format(),
      },
      true,
    );
  }

  function reset() {
    response.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loading.value = false;
    cursor.value = undefined;
    lastUpdated.value = undefined;
    itemsMap.value.clear();
    triggerRef(itemsMap);
    if (abortController.value) {
      abortController.value.abort();
    }
  }

  /**
   * helper function
   *
   * add item silently to itemsMap.
   * reactive dependencies will not be updated
   * @param item
   * @returns {boolean}
   */
  function addSilently(item) {
    if (!item.id) {
      return false;
    }

    itemsMap.value.set(item.id, item);

    return true;
  }

  /**
   * add item to itemsMap
   * reactive dependencies will be updated
   *
   * @param item
   * @returns {boolean}
   */
  function add(item) {
    if (addSilently(item)) {
      triggerRef(itemsMap);
      return true;
    }

    return false;
  }

  /**
   * add multiple items to itemsMap
   * reactive dependencies will be updated
   *
   * @param newItems []
   * @returns {boolean}
   */
  function addMultiple(newItems) {
    // add all items then only trigger the ref once
    newItems.forEach((item) => {
      addSilently(item);
    });

    triggerRef(itemsMap);
  }

  /**
   * helper function
   *
   * update an item silently in itemsMap so
   * that reactive dependencies will not be updated
   * @param item
   * @returns {boolean}
   */
  function updateSilently(item) {
    if (!item.id) {
      return false;
    }

    if (!itemsMap.value.get(item.id)) {
      return false;
    }

    itemsMap.value.set(item.id, item);

    return true;
  }

  /**
   * Update a single item in the itemsMap.
   * reactive dependencies will be updated
   *
   * @param item
   * @returns {boolean}
   */
  function update(item) {
    if (updateSilently(item)) {
      triggerRef(itemsMap);
      return true;
    }

    return false;
  }

  /**
   * Update a multiple items in the itemsMap.
   * reactive dependencies will be updated.
   *
   * @param targetItems []
   */
  function updateMultiple(targetItems) {
    targetItems.forEach((item) => {
      updateSilently(item);
    });

    triggerRef(itemsMap);
  }

  /**
   * Helper function
   *
   * Silently remove an item from itemsMap.
   * reactive dependencies will not be updated.
   *
   * @param item
   * @returns {boolean}
   */
  function removeSilently(item) {
    if (!item.id) {
      return false;
    }

    itemsMap.value.delete(item.id);

    return true;
  }

  /**
   * remove an item from itemsMap.
   * reactive dependencies will be updated.
   *
   * @param item
   * @returns {boolean}
   */
  function remove(item) {
    if (removeSilently(item)) {
      triggerRef(itemsMap);
      return true;
    }

    return false;
  }

  /**
   * remove multiple items from itemsMap.
   * reactive dependencies will be updated.
   *
   * @param targetItems []
   */
  function removeMultiple(targetItems) {
    targetItems.forEach((item) => {
      removeSilently(item);
    });

    triggerRef(itemsMap);
  }

  watch(
    [params, pageSize],
    () => {
      reset();
      loadMore();
    },
    { deep: true },
  );

  onUnmounted(reset);

  return {
    add,
    addMultiple,
    update,
    updateMultiple,
    remove,
    removeMultiple,
    loadMore,
    reset,
    loadUpdates,
    state: {
      pageSize,
      items,
      meta,
      loading,
      allLoaded,
      initialized,
      error,
    },
  };
}

export function useInboxV3Loader({ params: _params, count = 0, pageSize = 40 }) {
  const params = computed(() => {
    const __params = unref(_params);

    const include = __params.include || ['users'].join(',');

    return { ...__params, include };
  });

  const paramsBuffer = ref(params.value);

  watch(params, (newVal) => {
    paramsBuffer.value = newVal;
  });

  const queryParams = ref(paramsBuffer.value);

  debouncedWatch(
    paramsBuffer,
    (newVal) => {
      queryParams.value = newVal;
    },
    {
      debounce: 500,
      leading: true,
      trailing: true,
    },
  );

  const loader = Loader({
    url: computed(() => '/projects/api/v3/inbox/items.json'),
    params: queryParams,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
    order,
  });

  const initialized = computed(() => loader?.state?.meta?.value?.nextCursor !== undefined);

  // TODO: Needs tweak to teamwork/use/useRealTimeUpdates - using `rawEvent` for now
  useRealTimeUpdates((event, rawEvent) => {
    if (!event || !rawEvent) {
      return;
    }

    if (rawEvent.eventInfo.event === 'inboxBulkUpdateById') {
      const { ids } = rawEvent.eventInfo;

      function idToUpdatedInboxItem(id) {
        const item = loader.state.items.value.find((i) => i.id === id);
        if (!item) {
          return undefined;
        }
        return {
          ...item,
          ...rawEvent.eventInfo.changes,
        };
      }

      const updateItems = ids.map(idToUpdatedInboxItem).filter((i) => i !== undefined);

      loader.updateMultiple(updateItems);
    }

    if (event.type !== 'inboxItem') {
      return;
    }

    // Note: included data for `users` isn't supplied in the socket event response so we'll
    // just fake it by passing in the `createdBy` property (as this has all the data
    // required anyway)
    const inboxItem = normalizeInboxItem(
      (() => {
        return {
          ...rawEvent.eventInfo,
          createdBy: rawEvent.eventInfo.createdBy.id,
        };
      })(),
      (() => {
        const { createdBy } = rawEvent.eventInfo;

        return {
          [createdBy.id]: {
            id: createdBy.id,
            firstName: createdBy.firstname,
            lastName: createdBy.lastname,
            avatarUrl: createdBy.avatar_url,
          },
        };
      })(),
    );

    const { actionType } = inboxItem;

    if (actionType === 'new') {
      // We have no way of knowing if the new item matches the API filter for `sources` or
      // `searchTerm` so we need to perform a re-fetch
      if (params.value?.sources?.length || params.value?.searchTerm) {
        loader.loadUpdates();
        return;
      }

      if (inboxFiltersMatchItem(params, inboxItem)) {
        loader.add(inboxItem);
      }
    }

    if (actionType === 'updated') {
      if (!inboxFiltersMatchItem(params, inboxItem)) {
        loader.remove(inboxItem);
        return;
      }

      loader.update(inboxItem);
    }

    if (actionType === 'deleted') {
      loader.remove(inboxItem);
    }
  });

  useOptimisticUpdates(({ type, promise, action, inboxItem }) => {
    if (type !== 'inboxItem') {
      return;
    }

    if (!inboxItem.id) {
      return;
    }

    const oldInboxItem = JSON.parse(JSON.stringify(loader.state.items.value.find((item) => item.id)));
    const newInboxItem = JSON.parse(JSON.stringify(inboxItem));

    const OPERATION = Object.freeze({
      ADDED: 0,
      UPDATED: 1,
      REMOVED: 2,
    });

    const apply = (() => {
      if (action === 'create') {
        return () => {
          if (!inboxFiltersMatchItem(params, newInboxItem)) {
            return undefined;
          }

          loader.add(newInboxItem);

          return OPERATION.ADDED;
        };
      }

      if (action === 'update') {
        return () => {
          if (!inboxFiltersMatchItem(params, newInboxItem)) {
            loader.remove(newInboxItem);

            return OPERATION.REMOVED;
          }

          loader.update(newInboxItem);

          return OPERATION.UPDATED;
        };
      }

      if (action === 'delete') {
        return () => {
          loader.remove(newInboxItem);

          return OPERATION.REMOVED;
        };
      }

      return () => {};
    })();

    function rollback(_operation) {
      return () => {
        if (!_operation) {
          return;
        }

        if (_operation === OPERATION.ADDED) {
          loader.remove(newInboxItem);
          return;
        }

        if (_operation === OPERATION.UPDATED) {
          loader.update(oldInboxItem);
          return;
        }

        if (_operation === OPERATION.REMOVED) {
          loader.add(oldInboxItem);
        }
      };
    }

    promise.catch(rollback(apply()));
  });

  // Perform initial load
  loader.loadMore();

  return {
    ...loader,
    initialized,
    pageSize,
    params: readonly(queryParams),
    hasMore: computed(() => {
      return !!(loader?.state?.meta?.value?.nextCursor && loader?.state?.meta?.value?.nextCursor !== null);
    }),
  };
}
