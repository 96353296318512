import nativeAxios from 'axios';
import { useI18n } from '@/util';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useFileActions() {
  const api = useAxios();
  const { t } = useI18n();
  const toast = useLsToast();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const account = useCurrentAccount();

  function deleteFile(file) {
    const promise = api
      .delete(`/projects/api/v3/files/${file.id}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to delete the file'),
      })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'edited',
          projectId: file.projectId,
          fileId: file.id,
          affectedTaskIds: [file.taskId],
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  function deleteFileVersion(file) {
    const promise = api
      .delete(`/fileversions/${file.fileVersionId}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to delete the file version'),
      })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'edited',
          projectId: file.projectId,
          fileId: file.fileId,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  async function downloadFiles(fileIds = []) {
    return api.post(
      '/projects/api/v3/files/archive.json',
      { fileIds },
      { errorMessage: t('Failed to download the files') },
    );
  }

  /**
   * Returns a presigned URL to upload a file
   * @param {File} file
   * @returns {Promise<import('axios').AxiosResponse<{ url: string, ref: string }>}
   */
  function getPresignedUrl(file) {
    if (!file) {
      throw new Error('File is required');
    }
    return api.get('/projects/api/v1/pendingfiles/presignedurl.json', {
      params: {
        fileName: file.name,
        fileSize: file.size,
      },
    });
  }

  async function hasSpaceToUpload(fileSize) {
    const {
      data: { available },
    } = await api.get('/projects/api/v3/files/available.json');
    return fileSize <= available.bytes;
  }

  function restoreFile(file, type = 'file') {
    const id = type === 'file' ? file.id : file.fileVersionId || file.versionId;
    const restoreType = type === 'file' ? 'files' : 'fileversions';
    const promise = api
      .put(`/trashcan/${restoreType}/${id}/restore.json`, null, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to restore the file'),
      })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'reopened',
          projectId: file.projectId,
          fileId: file.id,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'reopened',
      file,
    });
    return promise;
  }

  /**
   *
   * @param {string} url The presigned URL to upload the file
   * @param {File} file The file to upload
   * @param {(progressEvent: import('axios').AxiosProgressEvent) => void} onUploadProgress Callback to track the upload progress
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  function uploadFile(url, file, onUploadProgress) {
    if (!url) {
      throw new Error('URL is required');
    }
    if (!file) {
      throw new Error('File is required');
    }
    // temp workaround to file uploading on production and staging
    // see https://digitalcrew.teamwork.com/app/tasks/20284502?c=10129853
    const headers = account.value?.isStaging
      ? { 'Content-Type': file.type }
      : {
          'X-Amz-Acl': 'public-read',
          'Content-Type': file.type,
        };

    return nativeAxios.put(url, file, { headers, onUploadProgress }).catch((error) => {
      toast.critical(t('Failed to upload file'));
      throw error;
    });
  }

  function updateFileDetails(fileId, newFileDetails) {
    const promise = api
      .patch(
        `/projects/api/v3/files/${fileId}.json`,
        { file: newFileDetails },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to update the file'),
        },
      )
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'update',
          projectId: newFileDetails.projectId,
          fileId,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file: { id: fileId, ...newFileDetails, displayName: newFileDetails.name },
    });
    return promise;
  }

  function uploadNewFileVersion(fileId, newFile) {
    const promise = api
      .post(
        `/projects/api/v3/files/${fileId}.json`,
        { fileversion: newFile },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to upload file'),
        },
      )
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'new',
          projectId: newFile.projectId,
          fileId,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file: { id: fileId, ...newFile },
    });
    return promise;
  }

  function lockFile(file) {
    const promise = api
      .put(`/projects/api/v3/files/${file.id}/lock.json`, null, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to lock the file'),
      })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'edited',
          projectId: file.projectId,
          fileId: file.id,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  function unlockFile(file) {
    const promise = api
      .put(`/projects/api/v3/files/${file.id}/unlock.json`, null, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to unlock the file'),
      })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'edited',
          projectId: file.projectId,
          fileId: file.id,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  return {
    deleteFile,
    deleteFileVersion,
    downloadFiles,
    getPresignedUrl,
    hasSpaceToUpload,
    lockFile,
    restoreFile,
    unlockFile,
    updateFileDetails,
    uploadFile,
    uploadNewFileVersion,
  };
}
