<script setup>
import { usePreferences } from '@/api';
import { useI18n } from '@/util';
import AppShellSidebarProjectsList from './AppShellSidebarProjectsList.vue';

const { t } = useI18n();
const { sidebarShowStarredProjects } = usePreferences();

const params = {
  searchTerm: null,
  include: 'companies,projectCategories',
  includeProjectUserInfo: true,
  'fields[projects]': 'id,name,isStarred,companyId,startPage,categoryId,logoIcon,logoColor',
  orderBy: 'name',
  orderMode: 'asc',
  onlyStarredProjects: true,
  onlyProjectsWithExplicitMembership: 1,
};
</script>

<template>
  <button
    type="button"
    size="sm"
    class="mx-6 my-1 inline-flex items-center gap-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]"
    data-identifier="ls-nav-sidebar-projects-starred"
    @click="sidebarShowStarredProjects = !sidebarShowStarredProjects"
  >
    {{ t('Starred') }}
    <LscIcon
      size="xs"
      icon="lsi-collapse"
      class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] transition-transform duration-300"
      :class="{ '-rotate-90': sidebarShowStarredProjects, '-rotate-180': !sidebarShowStarredProjects }"
    />
  </button>
  <AppShellSidebarProjectsList
    :show="sidebarShowStarredProjects"
    :params="params"
    :noProjectsMessage="t('No starred projects')"
    projectListType="starred"
  />
</template>
